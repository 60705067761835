import React from "react";
import { Row, Col } from "react-bootstrap";

const Card:React.FunctionComponent<{
  title: string,
  description: string | React.ReactNode,
  className?: string
}> = ({title, description, className}) => {
    return (
      <Col xs={12}>
        <Row className={`specs-card ${className}`}>
          <Col xs={{ span: 12 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <div className="title">{title}</div>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 8 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <div className="description">{description}</div>
          </Col>
        </Row>
      </Col>
    );
  };

export default Card;
