import { Dispatch, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TONClient from "../../ton-client/ton-client";

import { ActionTypes, RootState } from "../../types";
import { IAccount } from "../../interfaces";
import {
  setAccountsCount,
  setTransactionsCount,
  setBalanceTotal,
  setAccount,
  setSearchValue,
  setAccountCreationDate,
  setAccountTransactionsTotal,
  setAccountVolumeTotal,
  setAccountFeesTotal,
  setAccountBalanceGreaterThanCount,
  setActiveAccountsCount
} from "../../redux/actions";

import {
  getAccountsCount,
  getAccount,
  getAccountFirstMessage,
  getAccountTransactionsTotal,
  getAccountVolumeTotal,
  getAccountFeesTotal,
  getAccountBalanceGreaterThanCount,
  getTransactionsCount,
  getBalanceTotal,
  getActiveAccountsCount
  } from '../../ton-client/api';

import moment from "moment";

export interface IUsePulse {
  searchValue: string,
  trackedAccount:  IAccount | undefined,
  trackedAccountCreationDate: string,
  trackedAccountTransactionsTotal: number,
  trackedAccountVolumeTotal: number,
  trackedAccountFeesTotal: number,
  accountsTotal: number,
  transactionsTotal: number,
  balanceTotal: number,
  volumeTotal: number,
  balanceLTCount: number,
  activeAccountsCount: number,
}
export interface IGetAccount {
  setTrackingId: any
}

export const useAccountSearch = (): IGetAccount => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const setTrackingId = (id:string) => dispatch(setSearchValue(id));

  return useMemo(
    () => ({
      setTrackingId,
    }),
    [],
  );
}

export const usePulse = (): IUsePulse => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { totalCount: accountsTotal } = useSelector((state: RootState) => state.accounts);
  const { totalCount: transactionsTotal } = useSelector((state: RootState) => state.transactions);
  const {
    searchValue,
    activeAccountsCount,
    trackedAccount,
    accountCreationDate: trackedAccountCreationDate,
    accountTransactionsTotal: trackedAccountTransactionsTotal,
    accountVolumeTotal: trackedAccountVolumeTotal,
    accountFeesTotal: trackedAccountFeesTotal,
    balanceTotal,
    balanceLTCount,
    volumeTotal
  } = useSelector((state: RootState) => state.pulse);
  
  useEffect(() => {
    getAccount(searchValue)
      .then((value) => dispatch(setAccount(value[0])));
    dispatch(setAccountCreationDate(''));
    dispatch(setAccountTransactionsTotal(-1));
    dispatch(setAccountVolumeTotal(-1));
    dispatch(setAccountFeesTotal(-1));
    dispatch(setAccountBalanceGreaterThanCount(-1));
  }, [searchValue]);

  useEffect(() => {
    if (trackedAccount) {
      const { id, balance } = trackedAccount;
      getAccountFirstMessage(id)
        .then((value) => dispatch(setAccountCreationDate(value[0].created_at)));
      getAccountTransactionsTotal(id)
        .then((value) => dispatch(setAccountTransactionsTotal(value)));
      getAccountVolumeTotal(id)
        .then((value) => dispatch(setAccountVolumeTotal(value)));
      getAccountFeesTotal(id)
        .then((value) => dispatch(setAccountFeesTotal(parseFloat(value[0]))));
      getAccountBalanceGreaterThanCount(String(trackedAccount.balance))
        .then((value) => dispatch(setAccountBalanceGreaterThanCount(parseFloat(value[0]))));
    }
  }, [trackedAccount]);


  useEffect(() => {
    getAccountsCount().then((value => dispatch(setAccountsCount(value))));
    getTransactionsCount().then((value => dispatch(setTransactionsCount(value))));
    getBalanceTotal().then((value => dispatch(setBalanceTotal(parseFloat(value[0])))));
    getActiveAccountsCount(moment().subtract(1, "months").unix()).then((value => dispatch(setActiveAccountsCount(parseFloat(value[0])))));
    //dispatch(getAccountsCount(getAccountsCount());
    
  }, []);

  useEffect(() => {
    // const cardsWithNewKey: ICard[] = cardsByFilter.map((card: ICard) => {
    //   return { ...card, key: nanoid(10) };
    // });
    // const splitedCards: ICard[][] = getSplitedCardsByWidth(cardsWithNewKey, screenWidth);
    // dispatch(setSpliedCardsAction(splitedCards));
  }, []);

  useEffect(() => {
    // getTags()
    //   .then((response: AxiosResponse<ITag[]>) => dispatch(setTagsAction(response.data)))
    //   .catch((err: AxiosError) => console.log("getTags err: ", err, err.response));
  }, []);

  return useMemo(
    () => ({
      searchValue,
      activeAccountsCount,
      trackedAccount,
      trackedAccountCreationDate,
      trackedAccountTransactionsTotal,
      trackedAccountVolumeTotal,
      trackedAccountFeesTotal,
      accountsTotal,
      transactionsTotal,
      balanceTotal,
      balanceLTCount,
      volumeTotal
    }),
    [
      accountsTotal,
      activeAccountsCount,
      balanceTotal,
      balanceLTCount,
      transactionsTotal,
      searchValue,
      trackedAccount,
      trackedAccountCreationDate,
      trackedAccountVolumeTotal,
      trackedAccountFeesTotal,
      trackedAccountTransactionsTotal
    ],
  );
};
