import React, { useRef, useEffect, useState, ReactElement, ReactNode } from 'react';
import { SwiperOptions } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import styles from './slider.module.scss';
import _ from "lodash";
import classnames from "classnames/bind";

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// import Swiper core and required modules
// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const Slider = ({
  children,
  className
} : SwiperOptions & {
  children: ReactNode[];
  className?: string
}) : JSX.Element => {

  const cn = classnames.bind(styles)

  return (<Swiper
      modules={[Pagination]}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      direction={'vertical'} 
      mousewheel={true}
      pagination={{
        "clickable": true
      }} 
      className={cn("swiper", className)}>
        {children.map((item, index) => <SwiperSlide className={cn("swiper-slide")} key={index}>{item}</SwiperSlide>)}
    </Swiper>
  );
};

export default Slider;