const abi = `{
	"ABI version": 2,
	"header": ["time"],
	"functions": [
	  {
		"name": "onGetAll",
		"inputs": [
		  {"components":[{"components":[{"name":"addr","type":"address"},{"name":"name","type":"bytes"},{"name":"rounds","type":"uint64"},{"name":"succeededRounds","type":"uint64"},{"name":"participants","type":"uint32"},{"name":"minstake","type":"uint64"},{"name":"stake","type":"uint64"},{"name":"validatorWallet","type":"address"},{"name":"validatorFee","type":"uint8"},{"name":"validatorAssurance","type":"uint64"},{"name":"rewardYield","type":"uint64"},{"name":"participantReward","type":"uint64"}],"name":"depool","type":"tuple"},{"components":[{"name":"depoolness","type":"uint8"},{"name":"liveness","type":"uint8"},{"name":"successfulness","type":"uint8"},{"name":"participating","type":"uint8"},{"name":"popularity","type":"uint8"},{"name":"profitability","type":"uint8"},{"name":"experience","type":"uint8"},{"name":"uptime","type":"uint8"}],"name":"metrics","type":"tuple"},{"name":"capacity","type":"uint64"},{"name":"annualYield","type":"bytes"}],"name":"all","type":"map(uint128,tuple)"}
		],
		"outputs": [
		]
	  }
	],
	"data": [
	],
	"events": [
	]
  }`;

export default {
    abi
}