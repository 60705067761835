import React, { useRef, useState, useEffect } from "react";
import TONClient from "../../ton-client/ton-client";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FloatDecimals from '../utils/FloatDecimals';

import { Container, Row, Col } from "react-bootstrap";
import Card from './pulse/card'

import { default as balloonsAnimationData } from '../../assets/images/lottie/balloons.json'
import { default as balanceAnimationData } from '../../assets/images/lottie/balance.json'
import { default as transactionsAnimationData } from '../../assets/images/lottie/transactions.json'
import { default as volumeAnimationData } from '../../assets/images/lottie/volume.json'
import { default as feesAnimationData } from '../../assets/images/lottie/fees.json'
import { default as createdAnimationData } from '../../assets/images/lottie/created.json'

import { usePulse, useAccountSearch } from "../controllers/use-pulse";
import MetaDecorator from "../utils/metaDecorator";

const ACCOUNT_START_OF_PERIOD = 67395;

const TrackForm:React.FunctionComponent<{submitted?: string | undefined}> = ({submitted = undefined}) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);

  const {
    setTrackingId,
  } = useAccountSearch();
  const formik = useFormik({
    initialValues: {
      ton_id: ''
    },
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema={validationSchema}
    validate: values => {
      const errors: {ton_id?: string} = {};
      if (!values.ton_id) {
        errors.ton_id = 'Required';
      } else if (
        !/^0:[a-f0-9]{64,64}$/i.test(values.ton_id)
      ) {
        errors.ton_id = 'Invalid Surf address';
      }
      return errors;
    },
    onSubmit: ({ton_id}, { setSubmitting }) => {
      setTrackingId(ton_id);
      setSubmitting(true);
    }
  });

  useEffect(() => {
    if (submitted)
      formik.setSubmitting(false);
  }, [submitted]);

  //console.log(formik.setSubmitting(false))
  return (

    <form onSubmit={formik.handleSubmit}>
    <div className="form-group">
      <label>{formik.values.ton_id && "Everscale address"}</label>
      <button className={`clear ${formik.values.ton_id ? "active" : ""}`} onClick={() => formik.values.ton_id = ''} />
      <input
        ref={refInput}
        type="text"
        name="ton_id"
        className="form-control"
        placeholder="Everscale address"
        onChange={formik.handleChange}
        onFocus={() => setFocused(true)}
        onBlur={(props) => {
          setFocused(false);
          formik.handleBlur(props)
        }}
        value={formik.values.ton_id}
      />
      <div className="invalid-feedback d-block">
        {
          formik.errors.ton_id && formik.touched.ton_id && !(!focused && formik.errors.ton_id.toLocaleLowerCase() === "required")
          ? formik.errors.ton_id
          : ''
        }
      </div>
    </div>
    <button className="btn btn-primary" type="submit" disabled={!formik.values.ton_id || Boolean(formik.errors.ton_id) || Boolean(formik.isSubmitting)}>
      {formik.isSubmitting 
      ? "Tracking..."
      : "Track"}
    </button>
  </form>
  );
};


const Pulse:React.FunctionComponent<{}> = () => {
  const {
    accountsTotal,
    transactionsTotal,
    balanceTotal,
    balanceLTCount,
    volumeTotal,
    searchValue,
    trackedAccount,
    trackedAccountCreationDate,
    trackedAccountTransactionsTotal,
    trackedAccountVolumeTotal,
    trackedAccountFeesTotal,
    activeAccountsCount
  } = usePulse();

  // const validationSchema = Yup.object().shape({
  //   ton_id: Yup.string().required('Config name is Required'),

  //   serviceAccount: Yup.string().required('Service Account name is Required'),

  //   kubeConfig: Yup.mixed().nullable(),

  //   pullSecret: Yup.mixed(),

  //   regionCode: Yup.string(),

  //   regionList: Yup.array().of(
  //     Yup.object().shape({
  //       regionCode: Yup.object().nullable().required('Region is required'),

  //       zoneList: Yup.array()
  //         .of(
  //           Yup.object()
  //             .shape({
  //               zoneLabel: Yup.string().required('Zone label is required')
  //             })
  //             .required()
  //         )
  //         .unique('Duplicate zone label', (a) => a.zoneLabel)
  //     })
  //   )
  // });

  // if (balanceTotal !== -1) {
  //   console.log(balanceTotal)
  //   console.log(Math.floor(balanceTotal/(10**9)))
  //   console.log('---')
    
  // }; ; 

  const tonsToReadableFloat = (value: number): number => value/(10**9);

    return (
      <>
      <MetaDecorator
        title="Surf Pulse — live dashboard of users activity"
        description="An open data and real time stats on activity in the Surf app. Enter your wallet address and check information about your activity in the app comparing to other users."
        keywords="everscale, activity app stats, blockchain activity tracker, app active users statistics, blockchain real-time data, live app statistics"
      />
      <Container fluid="xl" className="content-container">
        <Row>
          <Col md={{ span: 8 }} lg={{ span: 8 }}>
            <h1 className="page-title">Surf’s up!</h1>
            <div className="page-description">
              Monitor stats, stakes and more in real-time, all with the Everscale Surf browser
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="pulse-overview d-none d-lg-flex"lg={{ span: 12 }}>
            <Card
              title={"Total accounts"}
              value={accountsTotal}
              graph={false}
              description={"with active status"}
              Icon={balloonsAnimationData}
            />
            <Card
              title={"Growth in " + (new Date()).getFullYear().toString()}
              value={accountsTotal && accountsTotal !== -1
                ? Math.floor((accountsTotal-ACCOUNT_START_OF_PERIOD)*100/ACCOUNT_START_OF_PERIOD) + '\u2009%'
                : -1}
              graph={false}
              description={accountsTotal && accountsTotal !== -1
                ? "+" + FloatDecimals(
                  ((accountsTotal-ACCOUNT_START_OF_PERIOD)*100/ACCOUNT_START_OF_PERIOD) / (moment().diff(moment().startOf("year"),'days'))
                , 2).toLocaleString('en') + "\u2009% every day"
                : "- every day"}
              Icon={feesAnimationData}
            />
            <Card
              title={"Recently active"}
              value={activeAccountsCount}
              graph={false}
              description={activeAccountsCount !== -1 && accountsTotal !== -1 ? FloatDecimals(activeAccountsCount*100/accountsTotal, 2).toLocaleString('en') + "\u2009% of the total" : "- of the total"}
              Icon={transactionsAnimationData}
            />
            <Card
              title={"Surfers balance"}
              value={Math.floor(tonsToReadableFloat(balanceTotal))}
              graph={false}
              description={ balanceTotal !== -1 && accountsTotal !== -1 ? Math.floor(tonsToReadableFloat(balanceTotal/accountsTotal)).toLocaleString('en') + " per account" : "- per account"}
              Icon={balanceAnimationData}
              className={"pulse-card-ton"}
            />
          </Col>
          <Col className="d-block d-lg-none" xs={{ span: 6 }}>
            <Card
              title={"Total accounts"}
              value={accountsTotal}
              graph={false}
              description={"with active status"}
              Icon={balloonsAnimationData}
            />
          </Col>
          <Col className="d-block d-lg-none" xs={{ span: 6 }}>
            <Card
              title={"Growth in " + (new Date()).getFullYear().toString()}
              value={accountsTotal && accountsTotal !== -1
                ? Math.floor((accountsTotal-ACCOUNT_START_OF_PERIOD)*100/ACCOUNT_START_OF_PERIOD) + '\u2009%'
                : -1}
              graph={false}
              description={accountsTotal && accountsTotal !== -1
                ? "+" + FloatDecimals(
                  ((accountsTotal-ACCOUNT_START_OF_PERIOD)*100/ACCOUNT_START_OF_PERIOD) / (moment().diff(moment().startOf("year"),'days'))
                , 2).toLocaleString('en') + "\u2009% every day"
                : "- every day"}
              Icon={feesAnimationData}
            />
          </Col>
          <Col className="d-block d-lg-none" xs={{ span: 6 }}>
            <Card
              title={"Recently active"}
              value={activeAccountsCount}
              graph={false}
              description={activeAccountsCount !== -1 && accountsTotal !== -1 ? FloatDecimals(activeAccountsCount*100/accountsTotal, 2).toLocaleString('en') + "\u2009% of the total" : "- of the total"}
              Icon={transactionsAnimationData}
            />
          </Col>
          <Col className="d-block d-lg-none" xs={{ span: 6 }}>
            <Card
              title={"Surfers balance"}
              value={Math.floor(tonsToReadableFloat(balanceTotal))}
              graph={false}
              description={ balanceTotal !== -1 && accountsTotal !== -1 ? Math.floor(tonsToReadableFloat(balanceTotal/accountsTotal)).toLocaleString('en') + " per account" : "- per account"}
              Icon={balanceAnimationData}
              className={"pulse-card-ton"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 12 }}>
            <h1 className="title title-track">Check your address</h1>
            <div className="hero blue">
              <div className="body">
                <TrackForm submitted={trackedAccount ? trackedAccount.id : undefined} />
              </div>
            </div>
          </Col>
        </Row>
        { trackedAccount && <>
        <Row>
          <Col lg={{ span: 12 }}>
            <div className="hero white">
              <div className="body">
                <Row className="pulse-overview pulse-overview-tracked">
                  <Col className="pulse-overview d-none d-lg-flex"lg={{ span: 12 }}>
                    <Card
                      title={"Balance"}
                      value={trackedAccount.balance ? FloatDecimals(TONClient.hex0xNanoToTons(String(trackedAccount.balance)), 2) : -1}
                      graph={false}
                      description={"on your account"}
                      Icon={balanceAnimationData}
                      className={"pulse-card-ton"}
                    />
                    <Card
                      title={"Account age"}
                      value={trackedAccountCreationDate ? moment().diff(moment.unix(Number(trackedAccountCreationDate)), "days") + " days": -1}
                      graph={false}
                      description={"since you joined"}
                      Icon={createdAnimationData}
                    />
                    <Card
                      title={"Transactions"}
                      value={trackedAccountTransactionsTotal}
                      graph={false}
                      description={(trackedAccountTransactionsTotal !== -1 && trackedAccountCreationDate) ? ((trackedAccountTransactionsTotal/(moment().diff(moment.unix(Number(trackedAccountCreationDate)), "days"))).toFixed(3) + " per day") : "- per day"}
                      Icon={transactionsAnimationData}
                    />
                    <Card
                      title={"Overall fees"}
                      value={(trackedAccountFeesTotal !== -1) ? (tonsToReadableFloat(trackedAccountFeesTotal)).toFixed(trackedAccountFeesTotal > 1000000000 ? 2 : 5) : -1}
                      graph={false}
                      description={(trackedAccountTransactionsTotal !== -1 && trackedAccountFeesTotal !== -1) ? `only ${FloatDecimals(tonsToReadableFloat(trackedAccountFeesTotal)/trackedAccountTransactionsTotal, 3)} per transaction` : "only - per transaction"}
                      Icon={feesAnimationData}
                      className={"pulse-card-ton"}
                    />
                  </Col>

                  <Col className="pulse-overview d-block d-lg-none" xs={{ span: 6 }}>
                    <Card
                      title={"Balance"}
                      value={trackedAccount.balance ? FloatDecimals(TONClient.hex0xNanoToTons(String(trackedAccount.balance)), 2) : -1}
                      graph={false}
                      description={"on your account"}
                      Icon={balanceAnimationData}
                      className={"pulse-card-ton"}
                    />
                  </Col>
                  <Col className="pulse-overview d-block d-lg-none" xs={{ span: 6 }}>
                    <Card
                      title={"Account age"}
                      value={trackedAccountCreationDate ? moment().diff(moment.unix(Number(trackedAccountCreationDate)), "days") + " days": -1}
                      graph={false}
                      description={"since you joined"}
                      Icon={createdAnimationData}
                    />
                  </Col>
                  <Col className="pulse-overview d-block d-lg-none" xs={{ span: 6 }}>
                    <Card
                      title={"Transactions"}
                      value={trackedAccountTransactionsTotal}
                      graph={false}
                      description={(trackedAccountTransactionsTotal !== -1 && trackedAccountCreationDate) ? ((trackedAccountTransactionsTotal/(moment().diff(moment.unix(Number(trackedAccountCreationDate)), "days"))).toFixed(3) + " per day") : "- per day"}
                      Icon={transactionsAnimationData}
                    />
                  </Col>
                  <Col className="pulse-overview d-block d-lg-none" xs={{ span: 6 }}>
                    <Card
                      title={"Overall fees"}
                      value={(trackedAccountFeesTotal !== -1) ? (tonsToReadableFloat(trackedAccountFeesTotal)).toFixed(trackedAccountFeesTotal > 1000000000 ? 2 : 5) : -1}
                      graph={false}
                      description={(trackedAccountTransactionsTotal !== -1 && trackedAccountFeesTotal !== -1) ? `only ${FloatDecimals(tonsToReadableFloat(trackedAccountFeesTotal)/trackedAccountTransactionsTotal, 3)} per transaction` : "only - per transaction"}
                      Icon={feesAnimationData}
                      className={"pulse-card-ton"}
                    />
                  </Col>
                </Row>
                <Row className="pulse-overview-tracked">
                  <Col className="">
                    <div className="buttons-flex">
                      <a target="_blank" href={`https://ton.live/accounts/accountDetails?id=0%3A${trackedAccount.id.split(":")[1]}&index=1`}><button type="button" className="btn btn-transparent btn-icon-after btn-icon-arrow-up-right">See more</button></a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </>}
      </Container>
    </>
    );
  };

export default Pulse;
