import {
  SET_SEARCH_VALUE,
  SET_ACCOUNT,
  SET_ACCOUNT_CREATION_DATE,
  SET_ACCOUNT_TRANSACTIONS_TOTAL,
  SET_ACCOUNT_FEES_TOTAL,
  SET_ACCOUNT_VOLUME_TOTAL,
  SET_BALANCE_TOTAL,
  SET_BALANCE_LT_COUNT,
  SET_ACTIVE_ACCOUNTS_COUNT
} from "./constants";
import { IAccount } from "../../interfaces";

export const setAccount = (account: IAccount) =>
  ({
    type: SET_ACCOUNT,
    payload: account,
  } as const);

export const setSearchValue = (searchValue: string) =>
  ({
    type: SET_SEARCH_VALUE,
    payload: searchValue,
  } as const);

export const setAccountCreationDate = (date: string) => {
    return ({
        type: SET_ACCOUNT_CREATION_DATE,
        payload: date,
    } as const);
    
}
export const setAccountTransactionsTotal = (total: number) =>{
    return ({
    type: SET_ACCOUNT_TRANSACTIONS_TOTAL,
    payload: total,
  } as const);
}
export const setAccountVolumeTotal = (total: number) =>{
    return ({
    type: SET_ACCOUNT_VOLUME_TOTAL,
    payload: total,
  } as const);
}
export const setAccountFeesTotal = (total: number) =>{
    return ({
    type: SET_ACCOUNT_FEES_TOTAL,
    payload: total,
  } as const);
}
export const setBalanceTotal = (total: number) =>{
    return ({
    type: SET_BALANCE_TOTAL,
    payload: total,
  } as const);
}
export const setAccountBalanceGreaterThanCount = (total: number) =>{
    return ({
    type: SET_BALANCE_LT_COUNT,
    payload: total,
  } as const);
}
export const setActiveAccountsCount = (total: number) =>{
    return ({
    type: SET_ACTIVE_ACCOUNTS_COUNT,
    payload: total,
  } as const);
}
