import React, { ReactNode } from "react";
import Lottie, { Options } from 'react-lottie';
import cn from "classnames";

const Card:React.FunctionComponent<{
  title: string,
  value: number | string,
  description?: ReactNode,
  content?: any,
  graph?: any,
  className?: string,
  Icon: any
}> = ({title, value, description, graph, className, content, Icon}) => {
  const defaultOptions:Options = {
    loop: true,
    autoplay: true, 
    animationData: Icon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const random = Math.round(Math.random()*30 - 10);
  
    return (
      <div className={`pulse-card ${className}`}>
        <Lottie options={defaultOptions}
                height={60}
                width={60}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}/>
        <h5>{(title)}</h5>
        {/* <div className="value">{(Number(value).toLocaleString('en'))}</div> */}
        <div className={value === -1 ? "value" : "value active"}>{value === -1 ? <div className="loader-spinner"></div> : (value.toLocaleString('en'))}</div>
        {description && <div className="description">{description}</div>}
        {graph && <div className={cn("pulse-card-graph", { "green": random > 0, "red": random <= 0 })}>
          <div className="value">{(random.toLocaleString('en'))}%</div>
          <svg width="109" height="12" viewBox="0 0 109 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
          <path d="M0.800049 10.5L47.8 8.49999L53.8 3.49999L58.8 4.49999L71.2 0.799988L83.8 10.5L85.8 5.49999L91.5 0.799988L97.8 3.49999H104.8L108.2 5.59999" strokeWidth="1.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="109" height="11.2" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        </div>}
        {(content)}
      </div>
    );
  };

export default Card;
