import React from "react";

const QRCode:React.FunctionComponent<{
  title: string,
  className?: string,
  Code: any
}> = ({title, className, Code}) => {
    return (
      <div className={`qr-code ${className}`}>
        <Code/>
        <span className="paragraph paragraph-label">{title}</span>
      </div>
    );
  };

export default QRCode;
