import React, { useLayoutEffect, useRef, useState } from "react"
import { Container, Row, Col, Modal, Button, Accordion } from "react-bootstrap"
import AccordionPanel from './staking/v-accordion-panel'

import { ReactComponent as Logo } from "../../assets/images/logo.svg"
import { ReactComponent as LogoCircled } from "../../assets/images/logo-circle.svg"
import Lottie from 'react-lottie'

import cn from "classnames"
import { Scrollspy } from "../utils/Scrollspy"

import SpecsCard from './scard/v-specs-card'
import PromoCard from './scard/v-promo-card'
import UsecasesCard from './scard/v-usecases-card'
import { ReactComponent as QRCodeStaking } from "../../assets/images/qr/scard.svg"

import { default as watermelonAnimationData } from '../../assets/images/lottie/scard/watermelon.json'
import { default as       girlAnimationData } from '../../assets/images/lottie/scard/girl.json'
import { default as       chefAnimationData } from '../../assets/images/lottie/scard/chef.json'
import { default as       handAnimationData } from '../../assets/images/lottie/scard/hand.json'
import { default as      monkeAnimationData } from '../../assets/images/lottie/scard/monke.json'

import { default as   scooterAnimationData } from '../../assets/images/lottie/scard/scooter.json'
import { default as     starsAnimationData } from '../../assets/images/lottie/scard/stars.json'
import { default as    balloonAnimationData } from '../../assets/images/lottie/scard/balloon.json'
import { default as       ufoAnimationData } from '../../assets/images/lottie/scard/ufo.json'
import { default as     genieAnimationData } from '../../assets/images/lottie/scard/genie.json'

import { ReactComponent as WavesIcon } from '../../assets/images/svg/scard/waves.svg'
import Parallax from 'parallax-js'

import MetaDecorator from "../utils/metaDecorator"
import { useScard } from "../controllers/use-scard"

import { STAKING_RATE } from "./";

const animations: Array<any> = [watermelonAnimationData, girlAnimationData, handAnimationData, chefAnimationData, monkeAnimationData];

const HeroParallax:React.FunctionComponent<{}> = () => {
  const parallaxRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const parallaxInstance = new Parallax(parallaxRef.current as HTMLElement, {
      scalarY: 3
    });
    if (window.innerWidth <= 767 && parallaxRef && parallaxRef.current) {
      parallaxRef.current.style.transform = `scale(${window.innerWidth / 560})`;
    }
    parallaxInstance.enable();
    window.addEventListener("resize", (e) => {
      const { innerWidth: width } = window;
      if (parallaxRef && parallaxRef.current && width <= 767) {  
        const scale = width / 560;
        parallaxRef.current.style.transform = `scale(${scale})`;
      }
    })

    return () => {
      parallaxInstance.destroy();
    };
  }, [])

  return <div id="parallax-scene" ref={parallaxRef as React.RefObject<HTMLDivElement>}>
    <div data-depth="1.9" className="parallax-waves">
      <WavesIcon  />
    </div>
    <div data-depth="0.6" className="parallax-ufo">
      <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: ufoAnimationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={230}
              width={160}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
    </div>
    <div data-depth="0.4" className="parallax-balloon">
      <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: balloonAnimationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={165}
              width={142}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
    </div>
    <div data-depth="0.25" className="parallax-genie">
      <Lottie options={{
        loop: true,
        autoplay: true, 
        animationData: genieAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={284}
              width={387}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
    </div>
    <div data-depth="0.5" className="parallax-scooter">
      <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: scooterAnimationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={94}
              width={94}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
    </div>
    <div data-depth="0.1" className="parallax-stars">
      <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: starsAnimationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={55}
              width={45}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
    </div>
  </div>
}


const SecurityCard:React.FunctionComponent<{}> = () => {

  const {
    usecasesCards,
    promoCards,
    techSpecs
  } = useScard();

  const whatIsStakingRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUsecases, setShowUsecases] = useState<boolean>(false);
  const [showSpecs, setShowSpecs] = useState<boolean>(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
    <MetaDecorator
      title="Everscale SecurityCard. Personalized and secure smart card"
      description="Hardware cards are back. Create multisig wallet and manage your assets together with partners or friends. Sign transactions by tapping SecurityCard to your mobile device."
      keywords="everscale, decentralized blockchain, multisig wallet, buy NFC card, smart contracts, customise NFC card, cryptocurrency wallet, free dapp, free crypto wallet, mobile dapp, staking pools, buy evers, EVER tokens"
    />
    <Container fluid="xl" className="content-container scard-main">
      <Row>
        <Col>
          <div className="hero">
            <h1 className="title">It's more than a&nbsp;hardware&nbsp;wallet</h1>
            <div className="page-description">
            Security Card is a unique NFC smart card — it keeps your data safe and can be adapted to your own needs
            </div>
            <div className="flex">
              <a rel="noopener noreferrer nofollow" target="_blank" href="https://t.me/joinchat/DORKTEkdxkozODgy"><button type="button" className="btn btn-primary btn-xl">Become a β-tester</button></a>
              <div className="qr-code"><QRCodeStaking/></div>
            </div>
            <HeroParallax/>
          </div>
        </Col>
      </Row>

      <Row className="scard-promo-cards">
        {promoCards.slice(0,4).map((card: any, index: number) => (
          <Col xs={{ span: 12 }} sm={{ span: 6 }}  md={{ span: 6 }} lg={{ span: 3 }} key={index}>
            <PromoCard
              key={index}
              index={index}
              className={card.className}
              title={card.title}
              description={card.description}
              Icon={animations[index]}
            />
          </Col>
        ))}
      </Row>
    </Container>
    {/* <Container id={"use-cases"} fluid="xl" className={cn("content-container use-cases")}>
      <Row>
        <Col lg={{ span: 12 }}>
            <h3 className="title title-large">Better with Surf</h3>
            <div ref={whatIsStakingRef} className="description">Use-cases with Ton Surf</div>

        </Col>
      </Row>
      <Row className="scard-use-cases-cards">

        {usecasesCards.map((card: any, index: number) => (
          <UsecasesCard
            key={index}
            className={card.className}
            title={card.title}
            description={card.description}
          />
        ))}
      </Row>
    </Container> */}
    {/* <Scrollspy sectionRefs={sectionRefs}>
      {({ elementsScrolled }) => {
        return (<> */}
        {/* <div className={cn("tech-specs-subheader", {"d-none": !elementsScrolled[0]})} style={elementsScrolled[1] ? {position: "absolute", backgroundColor: "#fdfdfd", top: document.querySelector<HTMLElement>(".faq-wrapper") ? document.querySelector<HTMLElement>(".faq-wrapper")!.offsetTop - document.querySelector<HTMLElement>(".tech-specs-header")!.getBoundingClientRect().height : 0, boxShadow: 'none', zIndex: 1000} : {}}>
          <Container fluid="xl" className={cn("content-container tech-specs tech-specs-header")}>
            <Row>
              <Col xs={12} className="flex">
                <div>
                  <h3 ref={whatIsStakingRef}>Technical specifications</h3>
                </div>
                <div>
                  <a rel="noopener noreferrer nofollow" target="_blank" href="https://t.me/joinchat/DORKTEkdxkozODgy"><button type="button" className="btn btn-primary">Become a β-tester</button></a>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        {/* <Container fluid="xl" id="tech-specs" ref={sectionRefs[0]} className={cn("content-container tech-specs tech-specs-header", {"scrolled": elementsScrolled[0]})}> */}
        <Container fluid="xl" id="tech-specs" ref={sectionRefs[0]} className={cn("content-container tech-specs tech-specs-header")}>
          <Row>
            <Col xs={12} className="flex">
                <div>
                  <h3 ref={whatIsStakingRef}>Technical specifications</h3>
                </div>
                <div>
                  <a rel="noopener noreferrer nofollow" target="_blank" href="https://t.me/joinchat/DORKTEkdxkozODgy"><button type="button" className="btn btn-primary">Become a β-tester</button></a>
                </div>

            </Col>
          </Row>
        </Container>
        <div className={cn("tech-specs-details-wrapper")}>
          <Container fluid="xl" className={cn("content-container tech-specs")}>
            <Row className="tech-specs-details">
              {techSpecs.map((card: any, index: number) => (
                <SpecsCard
                  key={index}
                  className={card.className}
                  title={card.title}
                  description={card.description}
                />
              ))}
            </Row>
          </Container>
          <div className={cn("tech-specs-details-wrapper-two")}>
            <div ref={sectionRefs[1]} className={"tech-specs-ref"}></div>
          </div>
        </div>
      {/* </>)}}
    </Scrollspy> */}

    <div className={"faq-wrapper"}>
      <Container fluid="xl" className="content-container faq">
        <Row>
          <Col lg={{ span: 12 }}>
              <h3>Security Card FAQs</h3>
              <div className="description">Look over these questions to allay your concerns about the Cards. Clarify everything is safe before you order your Card.</div>

              <Accordion defaultActiveKey="-1">
                <AccordionPanel
                  eventKey="0"
                  title="What is a Security Card?"
                >
                  <p>
                  It’s a plastic card with an NFC chip embedded. This chip stores the private key, which allows you to use the card for signing transactions. In the future, you can deploy it as an access card to enter a building or as a credit card to pay with.
                  </p>
                </AccordionPanel>
                <AccordionPanel 
                  eventKey="1"
                  title="How to use a Security Card? What advantages does it bring?">
                  <p>
                  A Security Card helps you to manage your assets safer. For example, you can deploy a Multisig wallet using the Card as a custodian. Thus, you will use two signs for each transaction: entering a PIN code and tapping your Card to the device.
                  </p>
                  
                </AccordionPanel>
                <AccordionPanel 
                  eventKey="2"
                  title="What about the personal data I specified when ordering a Card? How do you store and process it?">
                  <p>
                  No personal data collecting or behavioural data tracking — that’s what we compellingly stand for. When you order the Card, we do not collect or store your name and shipping address. We take care of this information and only encrypt it. Then, it goes through the contract to the delivery service.
                  </p>
                </AccordionPanel>
                <AccordionPanel 
                  eventKey="3"
                  title="What if someone else opens my parcel? Will he or she get access to my wallet?">
                  <p>Don’t worry, no one can access your wallet because the cards we ship are inactive. It’s only you who can activate the Card and only from the same wallet which you used to order it.</p>
                  
                </AccordionPanel>
                <AccordionPanel 
                  eventKey="4"
                  title="Is the Card linked to a specific wallet or device? Can I change it?">
                  <p>You can activate the Card only from the same wallet you used to order the Card. After activation, you can use the Card to deploy a Multisig wallet. But it doesn’t have to be a wallet you used to order the Card.</p>

                  <p>If you ordered the Card via the Surf web version or using a smartphone without NFC, you can activate the Card anyway using another device. The most important thing is to sign in on this device to your wallet which you used to order the Card.</p>
                  
                </AccordionPanel>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Container fluid="xl" className="content-container">
        <Row className="scard-promo-cards scard-promo-cards-lion">
          <Col className="lion-image d-block d-sm-none" xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 3 }}></Col>
          <Col className="d-lg-none" xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 2 }}>
            Coming soon...<br/><br/>
            <PromoCard
              className={promoCards[promoCards.length - 1].className}
              title={promoCards[promoCards.length - 1].title}
              description={promoCards[promoCards.length - 1].description}
              Icon={monkeAnimationData}
              index={4}
            />
          </Col>
          <Col className="d-none d-lg-block" lg={{ span: 3 }}>
            Coming soon...
          </Col>
          <Col className="d-none d-lg-block" lg={{ span: 4 }}>
            <PromoCard
              className={promoCards[promoCards.length - 1].className}
              title={promoCards[promoCards.length - 1].title}
              description={promoCards[promoCards.length - 1].description}
              Icon={null}
              index={4}
            />
          </Col>
          <Col className="lion-image d-none d-lg-block" xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 3 }}>
            <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: monkeAnimationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={120}
              width={220}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}/>
          </Col>
        </Row>
      </Container> */}
      <Modal
        show={showModal}
        onHide={handleClose}
        size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title><div className={"logo"}><Logo/></div>How to join waitlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} lg={4}>
              <dl>
                <dt className="title title-medium">Install Surf</dt>
                <dd className="paragraph paragraph-note">Install Surf from App Store or Google Play if you do not have it yet.</dd>
                <dt className="title title-medium">Navigate</dt>
                <dd className="paragraph paragraph-note">Tap the <LogoCircled/> icon in the upper left corner, go to Safety protection / Security Card.</dd>
                <dt className="title title-medium">Join</dt>
                <dd className="paragraph paragraph-note">Tap the Join waitlist and we will notify you about the opening of the card order.</dd>
              </dl>
            </Col>
            <Col xs={12} lg={8} className="modal-disclaimer">
              <h5 className="title-secondary title-secondary-medium">Security Card can be ordered only through the Surf application, and that’s why:</h5>
              <ul className="paragraph paragraph-text">
                <li>Surf is 100% secure and uses multi-factor user authentication;</li>
                <li>Your ordered card will be automatically linked to your Surf account, and nobody else, even us, can access it.</li>
              </ul>
              <a href="https://ever.surf/download/"><Button className="btn-lg">Install Surf</Button></a>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SecurityCard;
