// import { useEffect, ReactNode, InputHTMLAttributes } from "react";
import React, { useState, useEffect, useRef, ReactNode, InputHTMLAttributes } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Input.module.scss';
import { FormikContextType, useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { Icon } from "..";
// /import useOutsideClick from "../../utils/outsideClickCallback";
import { FormGroupProps } from "../../interfaces";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
  icon?: string,
  units?: ReactNode,
  description?: ReactNode,
  action?: ReactNode
}

// const InputFC = ({ field, form, ...props }: FieldProps) => {
//   return <input {...field} {...props} />;
// };

export const Input = <FormValues, >({
  label,
  value,
  placeholder,
  icon,
  units,
  disabled,
  description,
  name,
  className,
  action,
  ...props
} : FormGroupProps & InputProps & {
  name: keyof FormValues
}) => {
  
  // const errors:{[key: string]: any} = [];
  const {
    values,
    errors,
    touched,
    setFieldValue,
  } : {
    values: FormValues,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const [active, setActive] = useState<boolean>(false);
  // const [value, setValue] = useState<string>("");

  const refIcon = useRef<HTMLDivElement>(null);


  useEffect(() => {
    
  }, []);

  const hasError = errors[name];

  return <>
    <div className={`${styles.input}${disabled ? ' ' + styles['input-disabled'] : ''}${icon ? ' ' + styles['input-icon'] : ''}${className ? ' ' + className : ''}`}>
      <div className={`form-group ${styles.group}`}>
        {icon && <div className={`${styles.icon}`}>
          <Icon icon={icon} />
        </div>}
        <input
          name={name}
          value={value}
          disabled={disabled}
          className={`form-control ${styles['input-component']} ${value && styles['input-component-active']}`}
          //component={InputFC}
          placeholder={placeholder}
          {...props}
        />
        {units && <div className={`${styles.units}`}>
          {units}
        </div>}
        {action && <div className={`${styles.clear}`}>
          {action}
        </div>}
      </div>
      {(description || hasError) && <label className={`paragraph paragraph-footnote ${styles.label} ${hasError ? styles['label-error'] : ''}`}>
        {/* <>{label}</> */}
        <span>
        {hasError
          ? <div className="invalid-feedback d-block">
              {errors[name]}
            </div>
          : description
        }
      </span>
      </label>}
    </div>
  </>;
};
