import {
  SET_DEPOOLER
} from "./constants";

import { 
  Depooler
 } from "../../interfaces";

export const setDepooler = (depooler: Depooler) =>
  ({
    type: SET_DEPOOLER,
    payload: depooler,
  } as const);
