import React, { useRef } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import StatsCard from './staking/v-stat-card'
import PromoCard from './staking/v-promo-card'
import Calculator from './staking/v-calculator';
import AccordionPanel from './staking/v-accordion-panel';
import { ReactComponent as QRCodeStaking_ } from "../../assets/images/staking-qr-code.svg";
import { ReactComponent as QRCodeDeBot } from "../../assets/images/debot-qr-code.svg";
import { ReactComponent as DeBotIcon } from "../../assets/images/svg/debot-staking.svg";
import { ReactComponent as QRCodeStaking } from "../../assets/images/qr/staking.svg";

import { default as   fowlsAnimationData } from '../../assets/images/lottie/fowls.json'
import { default as   appleAnimationData } from '../../assets/images/lottie/apple.json'
import { default as   dudesAnimationData } from '../../assets/images/lottie/dudes.json'
import { default as  flowerAnimationData } from '../../assets/images/lottie/flower.json'
import { default as roosterAnimationData } from '../../assets/images/lottie/rooster.json'
import MetaDecorator from "../utils/metaDecorator";

export const STAKING_RATE = 6.0;

const Stacking:React.FunctionComponent<{}> = () => {

  const whatIsStakingRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const scrollHandler = (e:React.MouseEvent<HTMLButtonElement>):void => {
    e.preventDefault();
    whatIsStakingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
    <MetaDecorator
      title="Simple and advanced staking in trusted pools"
      description="Get passive income from holding Evers. Calculate your annual rewards from staking and get earning from the first day you staked in. Everscale staking in a few clicks: no risks for beginners."
      keywords="everscale, crypto deposit, staking calculator, start staking, easy staking, simple staking wallet, best staking, passive income with crypto, passive income staking, staking income, earn passive income crypto, crypto that pays dividends"
    />
    <Container fluid="xl" className="content-container">
      <Row>
        <Col>
          <div className="hero">
            <h1 className="title">Earn {STAKING_RATE.toLocaleString('en', {minimumFractionDigits: 1})}&#8201;% a year{`\n`} just for&nbsp;holding Evers</h1>
            <div className="page-description">
              Deposit tokens into blockchain and enjoy your rewards every day
            </div>
            <div className="buttons-container">
              <a href="https://uri.ever.surf/stake"><button type="button" className="btn btn-primary btn-xl">Stake now</button></a>
              <div className="qr-code"><QRCodeStaking/></div>
            </div>
            <button onClick={scrollHandler} type="button" className="btn">About staking</button>
            {/* <div className="qr-code">
              <div className="flex-shrink"><QRCodeStaking/></div>
              <div className="flex-grow">Open Surf with your smartphone camera by scanning this QR code</div>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="staking-stats-cards">
          <StatsCard
            title={"Staked value"}
            value={34366628}
            className={"staking-card-ton"}
          />
          <StatsCard
            title={"Reward paid"}
            value={1003783}
            className={"staking-card-ton"}
          />
          <StatsCard
            title={"Successful rounds"}
            value={99.9}
            digits={1}
            postfix={"\u2009%"}
          />
          <StatsCard
            title={"Active participants"}
            value={2359}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="about-staking">
          <div className="paragraph paragraph-label">What is Staking?</div>
          <span className="promo promo-medium">When you stake, you send your money to the blockchain thus helping it to work. In return, you earn a reward — your passive income grows.</span>
        </Col>
      </Row>
      <Row>
        <Col className="staking-promo-cards" lg={{ span: 12 }}>
          <PromoCard
            className={"rooster"}
            title={"Quick income"}
            description={"Daily earnings with controlling your funds and revenue"}
            Icon={roosterAnimationData}
          />
          <PromoCard
            className={"dudes"}
            title={"Safe and secure"}
            description={"Blockchain keeps you anonymous, no one can affect your reward"}
            Icon={dudesAnimationData}
          />
          <PromoCard
            className={"apple"}
            title={"Full control"}
            description={"No rewards losing — withdraw your funds lack at any time"}
            Icon={appleAnimationData}
          />
          <PromoCard
            className={"fowls"}
            title={"Max revenue"}
            description={"Replenishment is allowed anytime to increase your income"}
            Icon={fowlsAnimationData}
          />
          <PromoCard
            className={"flower"}
            title={"Your pitch in"}
            description={"Contribution to blockchain and decentralization"}
            Icon={flowerAnimationData}
          />
        </Col>
      </Row>
    </Container>
    <div className="calculator-wrapper">
      <Container fluid="xl" className="content-container">
        <Row>
          <Col className="" xs={{ span: 12 }}>
            <h3 className="title title-huge">Staking Calculator</h3>
            <div className="description paragraph paragraph-text">Estimate your approximate reward from staking</div>
          </Col>
          <Col className="" xs={{ span: 12 }} >
            <Calculator/>
    <div ref={whatIsStakingRef}></div>
          </Col>
        </Row>
      </Container>
    </div>
    <Container fluid="xl" className="content-container faq">
      <Row>
        <Col lg={{ span: 12 }}>
            <h3>Everscale Staking FAQs</h3>
            <div className="description">Staking is similar to gaining a percentage from bank deposits. You lend your money thus helping the blockchain to work and you gain rewards for this.</div>

            <Accordion defaultActiveKey="-1">
              <AccordionPanel
                eventKey="0"
                title="What's staking is all about?"
              >
                <p>
                  Staking allows you to get passive income from EVER you are holding in your wallet. You can direct these Evers to a Depool smart contract where they will help to validate blockchain transactions.
                  <br/> Participation in staking means:
                </p>
                <Row>
                  <Col md={6}>
                    <ul>
                      <li>Complete anonymity</li>
                      <li>Replenishment at any time</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul>
                      <li>Daily reward earnings </li>
                      <li>Full withdrawal at any time with no accrued reward losing</li>
                    </ul>
                  </Col>
                  <Col md={12}>
                    <a target="_blank" rel="noreferrer" href="https://help.ever.surf/en/support/solutions/articles/77000504903-what-is-staking-all-about-" className="outer act act-action">Learn more</a>
                  </Col>

                </Row>
              </AccordionPanel>
              <AccordionPanel 
                eventKey="1"
                title="How to start staking?">
                <p>
                  Before you start you need a Surf with Evers. 
                </p>
                <Row>
                  <Col md={8}>
                    <ol>
                      <li>Open Surf or click <b>Stake now</b> at the top of page.</li>
                      <li>On the main screen of the opened app, click <b>Earn staking rewards</b>.</li>
                      <li>Click <b>Stake</b>.</li>
                      <li>Specify the amount of EVER you want to stake. Minimum 100.5 Evers.</li>
                      <li>Click <b>Confirm and Send</b>.</li>
                      <li>Confirm your stake by entering your PIN-code, scanning your fingerprint or using face recognition.</li>
                    </ol>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    That's all. Your stake begins earning you rewards when the next validation cycle starts.<br/><br/>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <a target="_blank" rel="noreferrer" href="https://help.ever.surf/en/support/solutions/articles/77000504906-how-to-deposit-crystals-in-staking-using-surf-service-" className="outer act act-action">Learn more</a>
                  </Col>
                </Row>
              </AccordionPanel>
              <AccordionPanel 
                eventKey="2"
                title="How much should I stake?">
                <p>
                  Your stake amount may depend on the Depool you choose. Stakes in Surf Depool start from 100.5 EVER. After you make a stake, balance on your account must be at least 0.5 Evers.
                </p>
                <p>
                Note that total amount of Evers staken to Depools is limited. You cannot stake amount that exceed this limit.
                </p>
              </AccordionPanel>
              <AccordionPanel 
                eventKey="3"
                title="How much can I earn from staking?">
                <p>In general, you can earn {STAKING_RATE.toLocaleString('en', {minimumFractionDigits: 1})}% from the staked amount of Evers. Your rewards may vary depending on validators activity: number of processed transactions, total amount staken and number of participants.</p>
                <p>It takes 18 hours (one validation cycle) until you get your first reward. When validation cycle ends, your tokens and rewards restake automatically.</p>
                <p>Typically, Depools charge a pool fee. For example, Surf Validators takes a 8% fee from the reward amount.</p>
                <Row>
                  <Col md={12}>
                    <a target="_blank" rel="noreferrer" href="https://help.ever.surf/en/support/solutions/articles/77000504905-how-much-can-you-earn-from-staking-and-how-fast-" className="outer act act-action">Learn more</a>
                  </Col>
                </Row>
              </AccordionPanel>
              <AccordionPanel 
                eventKey="4"
                title="Is it risky? Can I withdraw my stake?">
                <p>You do not risk staking to Depools found for you by Surf. Unstake is flexible, you can withdraw crystals anytime without any charges.</p>
                <Row>
                  <Col md={8}>
                    <ol>
                      <li>Open the app in web or on your mobile device.</li>
                      <li>On the main screen, click <b>Earn staking rewards</b>.</li>
                      <li>Select Depool you have staked in.</li>
                      <li>Click <b>Withdraw</b>.</li>
                      <li>Confirm your unstake by entering your PIN-code, scanning your fingerprint or using face recognition.</li>
                    </ol>
                <Row>
                  <Col md={12}>
                    It can take up to 54 hours to get your stake and rewards back.<br/><br/>
                  </Col>
                </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <a target="_blank" rel="noreferrer" href="https://help.ever.surf/en/support/solutions/articles/77000504909-how-to-withdraw-crystals-from-staking-debot-" className="outer act act-action">Learn more</a>
                  </Col>
                </Row>
              </AccordionPanel>
            </Accordion>
            
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="debot-staking">
          <div className="card">
            <div className="title title-small">Try advanced staking to&nbsp;earn&nbsp;more</div>
            <span className="paragraph paragraph-note">Fine-tuning and complete decentralization — you decide where to stake but risks are also yours</span>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://uri.ever.surf/debot/0:66cb703cd63dd0b9eafbce702a9f838211ba1ea5ccce101dc81b98114d824b8a"><button className="btn btn-primary btn-lg">Stake with Debot</button></a>
          </div>
          <div className="card card-transparent">
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://uri.ever.surf/debot/0:66cb703cd63dd0b9eafbce702a9f838211ba1ea5ccce101dc81b98114d824b8a"><DeBotIcon/></a>
            <div className="title title-small">Staking&nbsp;Debot helps to stake</div>
            <QRCodeDeBot/>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Stacking;
