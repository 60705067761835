
// import { TONClient as TONWebClient, setWasmOptions } from 'ton-client-web-js';
import { TonClient as TONCoreClient } from '@tonclient/core';
import { libWeb, libWebSetup } from '@tonclient/lib-web';

import TONClient from '../ton-client/ton-client';

export default class Configs {

    static async setupTONClientSDK() {
        libWebSetup({
            binaryURL: '/assets/tonclient.wasm',
        });

        TONCoreClient.useBinaryLibrary!(libWeb);

        TONClient.network = new TONCoreClient({
            network: {
                endpoints: ['https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d'],
            },
        });
    }

    // static async setupLocalization() {
    //     await localizationService.load();
    // }

    static async setup() {
        // await TONCache.setAsyncStorage(AsyncStorage);
        await Promise.all([
            // this.setupLocalization(),
            this.setupTONClientSDK(),
            // this.setupLogs(),
        ]);
        // this.setupTONClients();
    }
}
