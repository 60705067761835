import { useMemo, useState } from "react";

import { default as   fowlsAnimationData } from '../../assets/images/lottie/fowls.json'
import { default as   appleAnimationData } from '../../assets/images/lottie/apple.json'
import { default as   dudesAnimationData } from '../../assets/images/lottie/dudes.json'
import { default as  flowerAnimationData } from '../../assets/images/lottie/flower.json'
import { default as roosterAnimationData } from '../../assets/images/lottie/rooster.json'

export interface IUseScard {
    usecasesCards: any,
    promoCards: any,
    techSpecs: any
}

export const useScard = (): IUseScard => {
  const [ exchangeMode, setExchangeMode ] = useState<string>('buy');
  const [ chartMode, setChartMode ] = useState<string>('chart');

  const usecasesCards = [{
    className : "login", 
    title : "Instant sign in",
    description : "Tap your Card to mobile device instead of entering a PIN code"
  }, {
    className : "transactions", 
    title : "Sign transactions",
    description : "Deploy a Multisig wallet with the Card and use it to sign transactions"
  }, {
    className : "access", 
    title : "Restore access",
    description : "If you lost your seed phrase, you can access and save your funds"
  }];

  const promoCards = [{
    className : "watermelon", 
    title : <>Cutting-edge,<br/>protect technology</>,
    description : "Single-chip design, EAL 6+ certification, cryptographic encryption and verified multisig wallet smart contract.",
    animation: fowlsAnimationData
  }, {
    className : "unicorn", 
    title : "Personalized from shipping",
    description : "The Card is personalized from the shipping and delivered to you safe and sound. No one can use it except you.",
    animation: fowlsAnimationData
  }, {
    className : "hand", 
    title : <>Easy to get,<br/>simple to use</>,
    description : "Fits in your pocket or card holder and works like other contactless cards. The Card feed nicely into your lifestyle.",
    animation: fowlsAnimationData
  }, {
    className : "chef", 
    title : "Open-sourced for developers",
    description : <><p>With the Security Card SDK you can program the card for your own smart contracts and use cases.</p><a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/tonlabs/TonNfcClientAndroid/blob/master/docs/CardActivation.md" className="outer act act-action">GitHub</a></>,
    animation: fowlsAnimationData
  }, {
    className : "lion", 
    title : "Free, simple and secure recovery",
    description : "Lost your card, device with Surf or forgot your PIN or Master password? No problem! Sign up to our free recovery service and restore access.",
    animation: undefined
  }]

  const techSpecs = [{
    className : "dimensions", 
    title : "Dimensions",
    description : "We wanted to make the hardware wallet convenient and easy to use and designed it in the shape of a plastic card 85,6 mm × 53,98 mm × 0,76 mm."
  }, {
    className : "hardware", 
    title : "Hardware",
    description : "To guarantee trouble-free operation and mitigate risks of exploits we used single chip NFC-only design (ISO 14443) and a certified secure chip SE CC EAL 6+"
  }, {
    className : "protection", 
    title : "Protection",
    description : <><p>Security and privacy are our priorities. We use the CC EAL 6+ Infineon Secure Element, protected from side-channel attacks by design. Your master password never leaves the card.</p><p>Your card is assigned to your account from the moment you place the order and can be activated only for the crypto wallet that was used to order it.</p></>
  }, {
    className : "extended-use", 
    title : "Extras",
    description : <><p>There is 120 KB of free space on the card, so as you could write your own data on it.</p><p>We provide you with the open source Security Card SDK, so as you could program the card with your own smart contracts and use it, for example, as a bank card, a building access card or whatever use case you need.</p><br/><a target="_blank" rel="noopener noreferrer nofollow" href="https://docs.ton.dev/86757ecb2/p/871a7d-sdk-releases/t/800abe" className="outer">Security Card SDK</a></>
  }]

  return useMemo(
    () => ({
        usecasesCards,
        promoCards,
        techSpecs
    }),
    [],
  );
};
