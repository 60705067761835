import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as Loader } from "../../assets/images/svg/loader.svg";
import MetaDecorator from "../utils/metaDecorator";
import {
  isMobile,
  isAndroid,
  isIOS
} from "react-device-detect";

const PATH_TG_TONDEV = 'https://t.me/TON_DEV';
const PATH_TG_TONLABS = 'https://t.me/tonlabs';
const PATH_TONDEV = 'https://ton.dev';
const PATH_TONSPACE = 'https://ton.space';
const PATH_GRAMSCAN = 'https://gramscan.io';
const PATH_TWITTER_TONLABS = 'https://twitter.com/tonlabs';
const PATH_GITHAB_TONLABS = 'https://github.com/tonlabs/';
const PATH_SURF_WEB = 'https://ever.surf/download/';
const PATH_SURF_ANDROID = 'https://play.google.com/store/apps/details?id=surf.ton';
const PATH_SURF_IOS = 'https://itunes.apple.com/us/app/ton-surf/id1481986831?mt=8';

const Get:React.FunctionComponent<{}> = () => {
  useEffect(() => {
    setTimeout(() => {
      if (isMobile) {
        if (isIOS) window.location.href = PATH_SURF_IOS;
        if (isAndroid) window.location.href = PATH_SURF_ANDROID;
      } else {
        window.location.href = PATH_SURF_WEB;
      }
    }, 2500);

  }, [])
  return (<>
    <MetaDecorator
      title="An Everscale blockchain browser, crypto wallet and private chat"
      description="Discover the Everscale blockchain. Create crypto wallet for free. Send and receive tokens, get passive income from holding crypto. Available for Android, iOS and on web."
      keywords="everscale, decentralized blockchain, blockchain app, blockchain client, smart contracts, cryptocurrency wallet, multisig wallet, free dapp, free crypto wallet, mobile dapp, staking pools, buy evers, EVER tokens"
    />
    <Container className={"loader-container"}>
      <Loader/>
    </Container>
  </>);
};

export default Get;
