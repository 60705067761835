import { Dispatch, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeBotClient from "../../debot-browser/client";

import { ActionTypes, RootState } from "../../types";
import { 
  Depooler,
  DepoolerNormalized
 } from "../../interfaces";
import {
  setDepooler,
} from "../../redux/actions";
import { number } from "yup/lib/locale";

export const MAX_DEPOOL_CAPACITY = 1033000;
export const DEPOOL_ROUNDS = 240;

export interface IUseDepooler {
  filter: keyof DepoolerNormalized;
  setFilter: Dispatch<React.SetStateAction<keyof DepoolerNormalized>>;
  depooler: Array<DepoolerNormalized>;
  desc: boolean;
  setDesc: Dispatch<React.SetStateAction<boolean>>;
}

export const useDepooler = (): IUseDepooler => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { depooler } = useSelector((state: RootState) => state.depooler);

  const [filter, setFilter] = useState<keyof DepoolerNormalized>('depooler');
  const [desc, setDesc] = useState<boolean>(true);
  const [depoolerNormalized, setDepoolerNormalized] = useState<Array<DepoolerNormalized>>([]);

  const tonsToReadableFloat = (value: number): number => value/(10**9);
  
  const calculateProfit = (percent: number, rounds: number): number => {
    if (rounds) return (1 + percent / 100) ** rounds;
    return 0;
  }

  useEffect(() => {
    if (!depooler) {
      DeBotClient.getDepooler()
        .then((value: any) => {
          console.log(value);
          if (value.all && Object.keys(value.all).length !== 0) {
            const ordered = Object.keys(value.all).sort((a, b) => value.all[b].metrics.depooler - value.all[a].metrics.depooler).reduce(
              (obj: any, key: any) => { 
                obj[key] = value.all[key]; 
                return obj;
              }, 
              {}
            );
            dispatch(setDepooler(ordered));
          }
        });
    }
  }, [depooler]);

  
  useEffect(() => {
    if (depooler) {
      setDepoolerNormalized(
        Object.values(depooler).map((el, index, array) => {
          return {
            addr: el.depool.addr,
            stake: tonsToReadableFloat(el.depool.stake),
            participants: Number(el.depool.participants),
            depooler: el.metrics.depoolness,
            liveness:  el.metrics.liveness,
            successfulness:  el.metrics.successfulness,
            participating:  el.metrics.participating,
            popularity:  el.metrics.popularity,
            profitability:  el.metrics.profitability,
            capacity: tonsToReadableFloat(el.capacity),
            minstake: tonsToReadableFloat(el.depool.minstake),
            annualYield: Number(DeBotClient.hexToUtf8(el.annualYield))
          }
        }).sort((a: DepoolerNormalized, b:DepoolerNormalized) => {
          if (filter !== "addr") { 
            return desc ? b[filter] - a[filter] : a[filter] - b[filter]
          } else { 
            return 0 
          }
        })
      );
    }
  }, [depooler, filter, desc]);

  return useMemo(
    () => ({
      depooler: depoolerNormalized,
      setFilter,
      filter,
      desc,
      setDesc
    }),
    [
      depoolerNormalized,
      filter,
      desc
    ],
  );
};
