import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as QRCode } from "../../assets/images/get-qr-code.svg";
import MetaDecorator from "../utils/metaDecorator";

const Main:React.FunctionComponent<{}> = () => {
  return (<>
    <MetaDecorator
      title="An Everscale blockchain browser, crypto wallet and private chat"
      description="Discover the Everscale blockchain. Create crypto wallet for free. Send and receive tokens, get passive income from holding crypto. Available for Android, iOS and on web."
      keywords="everscale, decentralized blockchain, blockchain app, blockchain client, smart contracts, cryptocurrency wallet, multisig wallet, free dapp, free crypto wallet, mobile dapp, staking pools, buy evers, EVER tokens"
    />
    <Container>
      <Row>
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <h1 className="title title-huge ">The most trusted Everscale Browser for DeFi and NFT</h1>
          <div className="description promo promo-small">Blockchain accessible for everyone</div>
          <a href="https://ever.surf/download/"><button className="btn btn-xl btn-primary">Get started</button></a>

          <div className="qr-code paragraph paragraph-footnote">
            <QRCode/>
            Get the Surf app for free — scan QR code with your smartphone
          </div>
          <div className="platforms">
          <a target="_blank" rel="noopener noreferrer nofollow" href="https://ever.surf/download/"><h6 className={"act act-action"}>Download the app</h6></a>
            <ul>
              <a target="_blank" rel="noopener noreferrer nofollow" href="https://itunes.apple.com/us/app/ton-surf/id1481986831?mt=8"><li className="ios">App Store</li></a>
              <a target="_blank" rel="noopener noreferrer nofollow" href="https://play.google.com/store/apps/details?id=surf.ton"><li className="android">Google Play</li></a>
              <a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/tonlabs/Surf-Releases/releases"><li className="github">GitHub</li></a>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </>);
};

export default Main;
