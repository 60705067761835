import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "../components/DropdownDeprecated";
import QRCode from './beta/v-qr-code'

import { ReactComponent as QRCodeIos } from "../../assets/images/qr/beta-ios.svg";
import { ReactComponent as QRCodeAndroid } from "../../assets/images/qr/beta-android.svg";
import { ReactComponent as QRCodeWeb } from "../../assets/images/qr/beta-web.svg";
import { ReactComponent as QRCodeGitHub } from "../../assets/images/qr/beta-github.svg";

import { default as betaAnimationData } from '../../assets/images/lottie/beta.json';
import Lottie, { Options } from 'react-lottie';
import MetaDecorator from "../utils/metaDecorator";

import {
  isMobile,
  isAndroid,
  isIOS
} from "react-device-detect";
import { Flex, FlexContainer, Icon } from "../components";

const Beta:React.FunctionComponent<{}> = () => {
  const defaultOptions:Options = {
    loop: true,
    autoplay: true, 
    animationData: betaAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
    <MetaDecorator
      title="Beta Testing in Everscale blockchain"
      description="Become a Surf Beta Tester. Join Beta either on iOS or Android apps. Gain early access to features. Scan QR code to get started and blend into our testers community."
      keywords="everscale, open beta testing, beta testing apps, beta testing crypto, beta testing release, join the beta testflight"
    />
    <Container fluid="xl" className="content-container">
      <Row>
        <Col>
          <div className="hero">
            <h1 className="title title-huge">Dive into β-Surf</h1>
            <div className="page-description paragraph-text">
            Explore new features and test new experiences among the first ones — bring your vision of future Surf
            </div>
            <div className="buttons">
              <a target="_blank" rel="noopener noreferrer nofollow" href="https://t.me/surfever"><button type="button" className="btn btn-primary btn-xl">Join β-Community</button></a>
              {/* <a target="_blank" rel="noopener noreferrer nofollow" href="https://forum.freeton.org/t/dive-into-surf/11863"><button type="button" className="btn btn-transparent btn-xl btn-icon btn-icon-arrow-up-right">Open Forum</button></a> */}
            </div>
              <FlexContainer
                className="qr-codes-container"
                direction="column"
                align="start"
              >
                <Flex>
                  <h6 className="qr-codes-title">
                    Mobile
                  </h6>
                </Flex>
                <Flex>
                  <FlexContainer
                   className="qr-codes"
                    direction="row"
                  >
                    {!(isAndroid && isMobile) && <a target="_blank" rel="noopener noreferrer nofollow" href="https://testflight.apple.com/join/uST00jJo"><QRCode
                      title="iOS"
                      className="flex-shrink"
                      Code={QRCodeIos}/></a>
                    }
                    {!(isIOS && isMobile) && <a target="_blank" rel="noopener noreferrer nofollow" href="https://play.google.com/apps/testing/surf.ton"><QRCode
                      title="Android"
                      className="flex-shrink"
                      Code={QRCodeAndroid}/></a>
                    }
                    {!isMobile && <a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/tonlabs/Surf-Releases/releases"><QRCode
                      title="APK"
                      className="flex-shrink"
                      Code={QRCodeGitHub}/></a>
                    }
                  </FlexContainer>
                </Flex>
                <Flex>
                  <h6 className="qr-codes-title">
                    Desktop
                  </h6>
                </Flex>
                <Flex>
                <FlexContainer
                  direction={"row"}
                  justify={"flex-start"}
                  align={"stretch"}
                  className={"qr-codes-dropdowns"}
                >
                  <Flex>
                    <Dropdown
                      isNav={true}
                      title={<><Icon icon={"windows"}/>Windows</>}
                      placeholder={"Business"}
                      options={[{
                        href: "https://distribution.services.ever.surf/download/channel/beta/windows_64/Ever-Surf.exe",
                        title: "Windows 64 bit, .exe"
                      },{
                        href: "https://distribution.services.ever.surf/download/channel/beta/windows_32/Ever-Surf.exe",
                        title: "Windows 32 bit, .exe"
                      }]}
                    />
                  </Flex>
                  <Flex>
                    <Dropdown
                      isNav={true}
                      title={<><Icon icon={"apple"} className={"icon-apple"}/>MacOS</>}
                      placeholder={"Business"}
                      // options={[{
                      //   href: "https://distribution.services.ever.surf/download/channel/release/osx_arm64/Ever-Surf.dmg",
                      //   title: "MacOS ARM, .dmg"
                      // },{
                      //   href: "https://distribution.services.ever.surf/download/channel/release/osx_64/Ever-Surf.dmg",
                      //   title: "MacOS Intel, .dmg"
                      // }]}
                      options={[{
                        href: "https://distribution.services.ever.surf/download/channel/beta/osx_64/Ever-Surf.dmg",
                        title: "MacOS Intel, .dmg"
                      }]}
                    />
                  </Flex> 
                  <Flex>
                    <Dropdown
                      isNav={true}
                      title={<><Icon icon={"ubuntu"}/>Linux</>}
                      placeholder={"Business"}
                      options={[{
                        href: "https://distribution.services.ever.surf/download/channel/beta/linux_64/Ever-Surf.rpm",
                        title: "CentOS 64 bit, .rpm"
                      },{
                        href: "https://distribution.services.ever.surf/download/channel/beta/linux_64/Ever-Surf.deb",
                        title: "Ubuntu/Debian 64 bit, .deb"
                      },{
                        href: "https://distribution.services.ever.surf/download/channel/beta/linux_32/Ever-Surf.rpm",
                        title: "CentOS 32 bit, .rpm"
                      },{
                        href: "https://distribution.services.ever.surf/download/channel/beta/linux_32/Ever-Surf.deb",
                        title: "Ubuntu/Debian 32 bit, .deb"
                      },]}
                    />
                  </Flex>
                </FlexContainer>
                </Flex>
              </FlexContainer>

            <div className="page-description">
              <h5>Beta builds</h5>
              <div className="note">
                Start Beta without signing up — scan a code for your mobile device
              </div>
            </div>

            <div className="beta-hero-animation">
              <Lottie options={defaultOptions}
                height={634}
                width={576}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}/>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Beta;
