/*
import {
    FieldAggregation,
} from '@tonclient/core';


export type Tons = string;
export type TonsNumber = number;
export type OtherCurrency = string | number;
export type Hexadecimal = number;

export type SortDirection = 'ASC' | 'DESC';

export type ConvertAddressArgs = {
    address: string,
    bounce: boolean,
    url: boolean,
}

export type AggregationFn = 'COUNT' | 'SUM';

export type CollectionName = 'blocks' | 'accounts' | 'transactions' | 'messages' | 'block_signatures';

export type AggregationParams = {
    filter: any,
    collection: CollectionName,
    fields?: { field: string, fn: AggregationFn }[]
}

export type LoadItemsParams = {
    itemsLoader?: (lastItem: any) => Promise<any[]> | any[],
    result?: string,
    order?: any,
    collection?: string,
    getFilter?: (lastItem: any) => Promise<any>,
    multiThread?: boolean,
}

export default class TONClient {
    static itemsLoadingMax: number = 50;
    static operationBatchLengthMax: number = 200;
    static itemsLoadingLimit: number = 25;
    static itemsLoadingDoubleLimit: number = TONClient.itemsLoadingLimit * 2;
    static itemsLoadingSmallLimit: number = 5;
    static itemsLoadingInfinity: number = 10000;
    static loadAllInRangeMultiThreadKeys: number[] = Array(16).map((e, i) => i);

    static collections = {
        accounts: 'accounts',
        blocks: 'blocks',
        messages: 'messages',
        transactions: 'transactions',
    };

    static operationTypes = {
        queryCollection: 'QueryCollection',
        aggregateCollection: 'AggregateCollection',
    };

    static addresses = {
        elector: '-1:3333333333333333333333333333333333333333333333333333333333333333',
        creator: '-1:0000000000000000000000000000000000000000000000000000000000000000',
    };

    static network: any;

    // static normalize = (nanoGrams: number) => {
    //     const x = new BigNumber(nanoGrams);
    //     const y = new BigNumber('1e9');
    //     return Number(x.dividedBy(y).toFixed(9));
    // };

    // query
    static async query(
        collection: string,
        //filter: { [string]: any },
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        const response = await this.network.net.query_collection({
            collection,
            filter,
            result,
            order: order || undefined,
            limit: limit || undefined,
        });

        return response?.result || [];
    }

    static async queryBlocks(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('blocks', filter, result, order, limit);
    }

    static async queryZeroStates(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('zerostates', filter, result, order, limit);
    }

    static async queryAccounts(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('accounts', filter, result, order, limit);
    }

    static async queryTransactions(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('transactions', filter, result, order, limit);
    }

    static async queryMessages(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('messages', filter, result, order, limit);
    }

    static async queryBlocksSignatures(
        filter: any,
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('blocks_signatures', filter, result, order, limit);
    }

    // aggregate
    static async aggregate(
        collection: string,
        filter: any,
        fields?: FieldAggregation[],
    ) {
        const response = await this.network.net.aggregate_collection({
            collection,
            filter,
            fields,
        });

        return response?.values || 0;
    }

    static aggregateBlocks(
        filter: any,
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('blocks', filter, fields);
    }

    static aggregateAccounts(
        filter: any,
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('accounts', filter, fields);
    }

    static aggregateTransactions(
        filter: any,
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('transactions', filter, fields);
    }

    static aggregateMessages(
        filter: any,
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('messages', filter, fields);
    }

    static aggregateBlocksSignatures(
        filter: any,
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('blocks_signatures', filter, fields);
    }
}
*/

// @flow
//import { TONLog } from '#TONUtility';
//import { UIFunction } from '@tonlabs/uikit.core';
import { ERROR, SUCCESS } from './statuses';
import Utils from './utils';
import {
    abiContract,
    FieldAggregation,
    ResponseHandler,
    ResultOfRunTvm,
    ResultOfSubscribeCollection,
} from '@tonclient/core';
import { AbiContract, ParamsOfEncodeMessage, ParamsOfRunGet, ParamsOfRunTvm } from '@tonclient/core/dist/modules';
import type { ParamsOfAggregateCollection, ParamsOfQueryOperation } from '@tonclient/core';

export type Tons = string;
export type TonsNumber = number;
export type OtherCurrency = string | number;
export type Hexadecimal = number;

export type SortDirection = 'ASC' | 'DESC';

export type ConvertAddressArgs = {
    address: string,
    bounce: boolean,
    url: boolean,
}

//const clientLog = new TONLog('TONClient');

export type NetKey = 'testnet' | 'net' | 'net2' | 'common1' | 'common2' | 'private' | 'main' | 'cinet' | 'os'
    | 'custom' | 'rust' | 'game0' | 'game1' | 'game2' | 'game3' | 'fld' | 'bsnnet' | 'toldnet';

export type AggregationFn = 'COUNT' | 'SUM';

export type CollectionName = 'blocks' | 'accounts' | 'transactions' | 'messages' | 'block_signatures';

export type AggregationParams = {
    filter: any,
    collection: CollectionName,
    log?: any,
    fields?: { field: string, fn: AggregationFn }[]
}

export type LoadItemsParams = {
    log?: any,
    itemsLoader?: (lastItem: any) => Promise<any[]> | any[],
    result?: string,
    order?: any,
    collection?: string,
    getFilter?: (lastItem: any) => Promise<any>,
    multiThread?: boolean,
}

export type DecodeMessageBodyArgs = {
    abi: AbiContract,
    body: string,
    internal: boolean,
    needToLog?: boolean,
}

export default class TONClient {
    static itemsLoadingMax: number = 50;
    static operationBatchLengthMax: number = 200;
    static itemsLoadingLimit: number = 25;
    static itemsLoadingDoubleLimit: number = TONClient.itemsLoadingLimit * 2;
    static itemsLoadingSmallLimit: number = 5;
    static itemsLoadingInfinity: number = 10000;
    static loadAllInRangeMultiThreadKeys: number[] = Array(16).map((e, i) => i);


    static collections = {
        accounts: 'accounts',
        blocks: 'blocks',
        messages: 'messages',
        transactions: 'transactions',
    };

    static operationTypes = {
        queryCollection: 'QueryCollection',
        aggregateCollection: 'AggregateCollection',
    };

    static addresses = {
        elector: '-1:3333333333333333333333333333333333333333333333333333333333333333',
        creator: '-1:0000000000000000000000000000000000000000000000000000000000000000',
    };

    static network: any;

    static formatDEC = '(format: DEC)';
    static timeout0 = '(timeout:0)';

    static convertedNanoToTons = {};

    static nanoToTons(value: Tons | number): number {
        return this.number(value) / (10 ** 9);
    }

    static tonsToNano(value: Tons | number): number {
        return this.number(value) * (10 ** 9);
    }

    static hexNanoToTons(value: Tons): number {
        return this.nanoToTons(this.hexToDec(value));
    }

    static hex0xNanoToTons(value: Tons): number {
        return this.nanoToTons(this.hex0xToDec(value));
    }

    static number(num?: string | number): number {
        return Number(num || 0);
    }

    static hex0xToDec(value: string): number {
        return this.hexToDec(value.substr(2));
        //return this.hexToDec(UIFunction.remove0x(value));
    }

    static optionalHex0xToDecConverter(hex: boolean): (param: string) => number {
        return hex ? (value) => TONClient.hex0xToDec(value) : (value) => TONClient.number(value);
    }

    static hexToDec(value: string | number = '0'): number {
        return parseInt(String(value), 16);
    }

    // static normalize = (nanoGrams: number) => {
    //     const x = new BigNumber(nanoGrams);
    //     const y = new BigNumber('1e9');
    //     return Number(x.dividedBy(y).toFixed(9));
    // };

    // static initLog(className: string, str: string | number = '', params?: any) {
    //     return new TONLog(`${className}${str ? ` ${str}` : ''}`, params);
    // }

    // query
    static async query(
        collection: string,
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        const response = await this.network.net.query_collection({
            collection,
            filter,
            result,
            order: order || undefined,
            limit: limit || undefined,
        });

        return response?.result || [];
    }

    static async queryBlocks(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('blocks', filter, result, order, limit);
    }

    static async queryZeroStates(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('zerostates', filter, result, order, limit);
    }

    static async queryAccounts(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('accounts', filter, result, order, limit);
    }

    static async queryTransactions(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('transactions', filter, result, order, limit);
    }

    static async queryMessages(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('messages', filter, result, order, limit);
    }

    static async queryBlocksSignatures(
        filter: { [key: string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number,
    ) {
        return this.query('blocks_signatures', filter, result, order, limit);
    }

    // aggregate
    static async aggregate(
        collection: string,
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        const response = await this.network.net.aggregate_collection({
            collection,
            filter,
            fields,
        });

        return response?.values || 0;
    }

    static aggregateBlocks(
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('blocks', filter, fields);
    }

    static aggregateAccounts(
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('accounts', filter, fields);
    }

    static aggregateTransactions(
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('transactions', filter, fields);
    }

    static aggregateMessages(
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('messages', filter, fields);
    }

    static aggregateBlocksSignatures(
        filter: { [key: string]: any },
        fields?: FieldAggregation[],
    ) {
        return this.aggregate('blocks_signatures', filter, fields);
    }

    // subscribe
    static subscribe(
        collection: string,
        filter: { [key: string]: any },
        result: string,
        responseHandler: ResponseHandler,
    ) {
        return this.network.net.subscribe_collection({
            collection,
            filter,
            result,
        }, (response: any) => responseHandler(response?.result, response?.status));
    }

    static subscribeBlocks(
        filter: { [key: string]: any },
        result: string,
        responseHandler: ResponseHandler,
    ) {
        return this.subscribe('blocks', filter, result, responseHandler);
    }

    static subscribeAccounts(
        filter: { [key: string]: any },
        result: string,
        responseHandler: ResponseHandler,
    ) {
        return this.subscribe('accounts', filter, result, responseHandler);
    }

    static subscribeTransactions(
        filter: { [key: string]: any },
        result: string,
        responseHandler: ResponseHandler,
    ) {
        return this.subscribe('transactions', filter, result, responseHandler);
    }

    static subscribeMessages(
        filter: { [key: string]: any },
        result: string,
        responseHandler: ResponseHandler,
    ) {
        return this.subscribe('messages', filter, result, responseHandler);
    }

    static unsubscribe(args: ResultOfSubscribeCollection) {
        return this.network.net.unsubscribe(args);
    }

    static encodeMessage(args: ParamsOfEncodeMessage) {
        return this.network.abi.encode_message(args);
    }

    static async decodeMessageBody({
        abi,
        body,
        internal,
        needToLog = false,
    }: DecodeMessageBodyArgs) {
        const result = await this.network.abi.decode_message_body({
            abi: abiContract(abi),
            body,
            is_internal: internal,
        });

        needToLog && console.log('decodeMessageBody', result);
        return result;
    }

    static hexToBase64(str: string): string {
        if (!str) {
            return '';
        }

        return Buffer.from(str, 'hex').toString('base64');
    }

    static base64ToHex(str: string): string {
        if (!str) {
            return '';
        }

        return Buffer.from(str, 'base64').toString('hex');
    }

    static convertAddress(params: any) {
        return this.network.utils.convert_address(params);
    }

    static async convertAddressToHex(address?: string) {
        if (!address) {
            return null;
        }

        try {
            const result = await TONClient.convertAddress({
                address,
                output_format: {
                    type: 'Hex',
                },
            });
            return result.address;
        } catch (e) {
            console.debug('Error, converting address to hex:', e);
            return null;
        }
    }

    static async convertAddressToBase64(args: ConvertAddressArgs) {
        const { address, bounce, url } = args;
        try {
            const result = await TONClient.convertAddress({
                address,
                output_format: {
                    type: 'Base64',
                    test: false,
                    bounce,
                    url,
                },
            });
            return result.address;
        } catch (e) {
            console.debug('Error, converting address to base64:', e);
            return null;
        }
    }

    // FieldAggregation = {
    //     field: string;
    //     fn: AggregationFn;
    // }
    //
    // export type AggregationFn = 'COUNT' | 'SUM';

    static async aggregateItems({
        filter,
        collection,
        fields = [{ field: '', fn: 'COUNT' }],
    }: AggregationParams) {
        try {
            const docs = await this.aggregate(collection, filter, fields as FieldAggregation[]);

            const count = docs && Number(docs[0]);
            console.info(SUCCESS, count);
            return count;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    static async loadAllInRange(args: LoadItemsParams, prevItemsAr: any[] = [], itemsById: { [param: string]: boolean } = {}): Promise<any> {
        const {
            collection,
            getFilter = (item: any) => ({}),
            order,
            result,
            multiThread = false,
            // for single thread
            itemsLoader = (item: any) => [],
            log,
        } = args;

        let operations: any[] = [];
        let newItemsAr: any[] = [];

        // Loading newItems
        try {
            if (multiThread) {
                const loadAllInRangeMultiThreadKeys: number[] = Array(16).map((e, i) => i);
                // setting filters and pagination for each operation
                const filters: Array<any> = await Promise.all(loadAllInRangeMultiThreadKeys.map(i => {
                    const lastItem = prevItemsAr[i] ? prevItemsAr[i][prevItemsAr[i]?.length - 1] : undefined;
                    return getFilter(lastItem);
                }));

                // setting operations width filters for multiThread mode
                loadAllInRangeMultiThreadKeys.forEach(i => {
                    operations = [...operations, (prevItemsAr[i]?.length % TONClient.itemsLoadingMax > 0) ? null : {
                        order,
                        result,
                        collection,
                        filter: {
                            ...filters[i],
                            id: {
                                ge: `${Number(i).toString(16)}`,
                                le: `${Number(i).toString(16)}z`,
                                ...filters[i]?.id,
                            },
                        },
                    }];
                });

                newItemsAr = await this.batchQuery(operations);

                log && log.debug(newItemsAr, SUCCESS, Utils.parseArray(newItemsAr));
            } else {
                const lastItem = prevItemsAr[prevItemsAr.length - 1];
                newItemsAr = await itemsLoader(lastItem);

                log && log.debug(lastItem, SUCCESS, newItemsAr);
            }
        } catch (e) {
            log && log.error(ERROR, e);
        }

        // count of loaded items
        const newItemsLength = multiThread
            ? newItemsAr.filter(items => items.length > 0).length // count of non-empty responses
            : newItemsAr.length;

        if (newItemsLength === 0) {
            return multiThread ? Utils.parseArray<any>(prevItemsAr) : prevItemsAr;
        }

        let addedCount = 0;
        newItemsAr.forEach((element, index) => {
            if (multiThread) {
                // for each operation result we check the elements for originality
                element.forEach((item: any) => {
                    Utils.callIfNotDuplicate(item.id, itemsById, () => {
                        if (!prevItemsAr[index]) prevItemsAr[index] = [];
                        prevItemsAr[index].push(item);
                        addedCount += 1;
                    });
                });
            } else {
                Utils.callIfNotDuplicate(element.id, itemsById, () => {
                    prevItemsAr.push(element);
                    addedCount += 1;
                });
            }
        });

        if (addedCount === 0) {
            return multiThread ? Utils.parseArray<any>(prevItemsAr) : prevItemsAr;
        }

        // checking for the need for the next request
        const needNewRequest = multiThread
            ? newItemsAr.filter(items => items.length === TONClient.itemsLoadingMax).length > 0
            : newItemsAr.length === TONClient.itemsLoadingMax;

        if (needNewRequest) {
            return TONClient.loadAllInRange(args, prevItemsAr, itemsById);
        }

        return multiThread ? Utils.parseArray<any>(prevItemsAr) : prevItemsAr;
    }
/*
    static async loadAllInList(idsList: string[], queryArgs: any) {
        const subLists = Utils.divideBySubLists(idsList);
        const { result, collection, getFilter } = queryArgs;

        const resultQuery = await this.batchQuery(subLists.map(subList => ({
            result,
            collection,
            filter: getFilter(subList),
        })));

        return resultQuery.reduce((prev, curr) => [...prev, ...curr], []);
    }
*/
    static async batchQuery(operations: ParamsOfQueryOperation[], type: string = this.operationTypes.queryCollection) {
        const operationFragment = Utils.divideBySubLists(operations, this.operationBatchLengthMax);

        const batchQueryResults = await Promise.all(operationFragment.map(fragment => {
            const requestsAreEmpty: Array<any> = [];
            const newFragment: Array<any> = [];
            if (type === this.operationTypes.queryCollection) {
                fragment.forEach(operation => {
                    requestsAreEmpty.push(operation === null);
                    if (operation !== null) {
                        newFragment.push(operation);
                    }
                });
            }
            return this.network.net.batch_query({
                operations: (type !== this.operationTypes.queryCollection ? fragment : newFragment).map(operation => ({
                    type,
                    ...operation,
                })),
            }).then(({ results }: any) => {
                if (type === this.operationTypes.queryCollection) {
                    let i = -1;
                    return {
                        results: requestsAreEmpty.map(isEmpty => {
                            if (isEmpty) return [];
                            i += 1;
                            return results[i];
                        }),
                    };
                }
                return { results };
            });
        }));

        const result: any = batchQueryResults.reduce((prev, curr) => [...prev, ...curr.results], []);

        return result;
    }

    static async batchQueryMessages(operations: any[]) {
        return this.batchQuery(operations.map(operation => ({
            ...operation,
            collection: this.collections.messages,
        })));
    }

    static async batchQueryBlocks(operations: ParamsOfQueryOperation[]) {
        return this.batchQuery(operations.map(operation => ({
            ...operation,
            collection: this.collections.blocks,
        })));
    }

    static async batchQueryTransactions(operations: ParamsOfQueryOperation[]) {
        return this.batchQuery(operations.map(operation => ({
            ...operation,
            collection: this.collections.transactions,
        })));
    }

    static async batchQueryAccounts(operations: ParamsOfQueryOperation[]) {
        return this.batchQuery(operations.map(operation => ({
            ...operation,
            collection: this.collections.accounts,
        })));
    }

    static async batchAggregateCollection(operations: ParamsOfQueryOperation[]) {
        const batchQueryResults = await this.batchQuery(operations, this.operationTypes.aggregateCollection);

        return batchQueryResults;
    }
/*
    static async batchAggregateMessages(paramsAr: ParamsOfAggregateCollection[]) {
        return this.batchAggregateCollection(paramsAr.map(params => ({
            collection: this.collections.messages,
            ...params,
        })));
    }

    static async batchAggregateBlocks(paramsAr: ParamsOfAggregateCollection[]) {
        return this.batchAggregateCollection(paramsAr.map(params => ({
            collection: this.collections.blocks,
            ...params,
        })));
    }

    static async batchAggregateTransactions(paramsAr: ParamsOfAggregateCollection[]) {
        return this.batchAggregateCollection(paramsAr.map(params => ({
            collection: this.collections.transactions,
            ...params,
        })));
    }

    static async batchAggregateAccounts(paramsAr: ParamsOfAggregateCollection[]) {
        return this.batchAggregateCollection(paramsAr.map(params => ({
            collection: this.collections.accounts,
            ...params,
        })));
    }
*/
    static arrayFromCONS(cons: any[]): any[] {
        const result = [];
        let item = cons;
        while (item) {
            result.push(item[0]);
            item = item[1];
        }
        return result;
    }

    static isTxSignedByNthCustodian(confMask: number, custodianIndex: number): boolean {
        return Boolean(confMask >> custodianIndex & 1);
    }
}
