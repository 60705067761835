import gsap from "gsap";
import React, { useEffect, useState } from "react";

const myObject = {
  totalValue: 0
};

const Card:React.FunctionComponent<{
  title: string,
  value: number,
  postfix?: string,
  digits?: number,
  className?: string
}> = ({title, value, postfix = '', className, digits = 0}) => {
  myObject.totalValue = 0;

  const multiplier:number = 10**digits;

  const [count, setCount] = useState(value*multiplier);

  useEffect(() => {
    gsap.to(myObject, {
      duration: 3,
      ease: "power4.out",
      totalValue: value*multiplier,
      roundProps: "totalValue",
      onUpdate: () => {
        setCount(myObject.totalValue);
      }
    });
  }, [value]);

    return (
      <div className={`stat-card ${className}`}>
        <div className="value-wrapper"><div className="value">{(count/multiplier).toLocaleString('en', {minimumFractionDigits: digits}) + postfix}</div></div>
        <div className="title">{(title)}</div>
      </div>
    );
  };

export default Card;
