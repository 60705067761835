import { FunctionComponent } from "react";
import { Helmet } from 'react-helmet-async';

const MetaDecorator: FunctionComponent<{title?: string, description?: string, keywords?: string}> = ({
    title = "Ton Surf",
    description="A free, private and easy blockchain interface for smart people.", 
    keywords
  }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default MetaDecorator;
