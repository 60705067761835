import { ActionTypes } from "../../types/action-types";
import { IAccount } from "../../interfaces";
import {
  SET_SEARCH_VALUE,
  SET_ACCOUNT_CREATION_DATE,
  SET_ACCOUNT,
  SET_ACCOUNT_TRANSACTIONS_TOTAL,
  SET_ACCOUNT_FEES_TOTAL,
  SET_ACCOUNT_VOLUME_TOTAL,
  SET_BALANCE_TOTAL,
  SET_BALANCE_LT_COUNT,
  SET_ACTIVE_ACCOUNTS_COUNT
} from "../actions/constants";

interface IInitialState {
  searchValue: string,
  trackedAccount: IAccount | undefined,
  accountCreationDate: string,
  accountTransactionsTotal: number,
  accountVolumeTotal: number,
  accountFeesTotal: number,
  balanceTotal: number,
  volumeTotal: number,
  balanceLTCount: number,
  activeAccountsCount: number,
}

const initialState: IInitialState = {
  searchValue: '', 
  trackedAccount: undefined,
  accountCreationDate: '',
  accountTransactionsTotal: -1,
  accountVolumeTotal: -1,
  accountFeesTotal: -1,
  balanceTotal: -1,
  volumeTotal: -1,
  balanceLTCount: -1,
  activeAccountsCount: -1
};

export const pulseReducer = (
  state: IInitialState = initialState, 
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_SEARCH_VALUE:
      return { ...state, searchValue: payload as string};
    case SET_ACCOUNT:
      return { ...state, trackedAccount: payload  as IAccount};
    case SET_ACCOUNT_CREATION_DATE:
      return { ...state, accountCreationDate: payload as string};
    case SET_ACCOUNT_TRANSACTIONS_TOTAL:
      return { ...state, accountTransactionsTotal: payload as number};
    case SET_ACCOUNT_VOLUME_TOTAL:
      return { ...state, accountVolumeTotal: payload as number};
    case SET_ACCOUNT_FEES_TOTAL:
      return { ...state, accountFeesTotal: payload as number};
    case SET_BALANCE_TOTAL:
      return { ...state, balanceTotal: payload as number };
    case SET_BALANCE_LT_COUNT:
      return { ...state, balanceLTCount: payload as number };
    case SET_ACTIVE_ACCOUNTS_COUNT:
      return { ...state, activeAccountsCount: payload as number };
    default:
      return state;
  }
};