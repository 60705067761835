import React, { useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import cn from "classnames";
import {
  isMobile,
  isSafari
} from "react-device-detect";
import ScrollToTop from "../utils/scrollToTop";

import Main from "./main";
import Staking from "./staking";
import SecurityCard from "./scard";
import Beta from "./beta";
import Content from "./content";
import Pulse from "./pulse";
import Depooler from "./depooler";
import Donate from "./donate";
import Footer from "./footer";
import Header from "./header";
import Get from "./get";

const Router:React.FunctionComponent<{}> = () => {
  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];
  
  return (
    <div className={cn("ws-app", useLocation().pathname.split('/').filter(Boolean)[0], {"isMobile": isMobile, "isSafari": isSafari, "index-page": !location})}>
      <ScrollToTop/>
      <Header/>
      <main>
        <Switch>
          <Route
            path="/staking"
            render={( ) => <Staking/>}
          />
          <Route
            path="/pulse"
            render={(props) => <Pulse/>}
          />
          <Route
            path="/depooler"
            render={(props) => <Depooler/>}
          />
          <Route
            path="/features"
            render={(props) => <Content path="features" />}
          />
          <Route
            path="/donate"
            render={(props) => <Donate/>}
          />
          <Route
            path="/beta"
            render={(props) => <Beta/>}
          />
          <Route
            path="/scard"
            render={(props) => <SecurityCard/>}
          />
          <Route
            path="/get"
            render={(props) => <Get/>}
          />
          <Route path="/legal/:id">
            <Content />
          </Route>
          <Route path="/" exact={true} render={(props) => <Main/>} />
          <Redirect to='/' />
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default Router;
