import { ActionTypes } from "../../types/action-types";
import {
  SET_DEPOOLER
} from "../actions/constants";

import { 
  Depooler
 } from "../../interfaces";

interface IInitialState {
  depooler: Depooler | undefined;
}

const initialState: IInitialState = {
  depooler: undefined
};

export const depoolerReducer = (
  state: IInitialState = initialState, 
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_DEPOOLER:
      return { ...state, depooler: payload as Depooler};
    default:
      return state;
  }
};