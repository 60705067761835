import { ActionTypes } from "../../types/action-types";
import {
  SET_ACCOUNTS_COUNT
} from "../actions/constants";

interface IInitialState {
  totalCount: number;
}

const initialState: IInitialState = {
  totalCount: -1
};

export const accountsReducer = (
  state: IInitialState = initialState, 
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_ACCOUNTS_COUNT:
      return { ...state, totalCount: payload as number};
    default:
      return state;
  }
};