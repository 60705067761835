export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const SET_ACCOUNTS_COUNT = "GET_ACCOUNTS_COUNT";
export const SET_ACTIVE_ACCOUNTS_COUNT = "SET_ACTIVE_ACCOUNTS_COUNT";
export const SET_TRANSACTIONS_COUNT = "SET_TRANSACTIONS_COUNT";
export const SET_BALANCE_TOTAL = "SET_BALANCE_TOTAL";
export const SET_BALANCE_LT_COUNT = "SET_BALANCE_LT_COUNT";

export const SET_ACCOUNT = "GET_ACCOUNT";
export const SET_ACCOUNT_CREATION_DATE = "SET_ACCOUNT_CREATION_DATE";
export const SET_ACCOUNT_TRANSACTIONS_TOTAL = "SET_ACCOUNT_TRANSACTIONS_TOTAL";
export const SET_ACCOUNT_VOLUME_TOTAL = "SET_ACCOUNT_VOLUME_TOTAL";
export const SET_ACCOUNT_FEES_TOTAL = "SET_ACCOUNT_FEES_TOTAL";

export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const SET_DEPOOLER = "SET_DEPOOLER";

