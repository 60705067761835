import React, { ReactNode } from "react";

const myObject = {
  totalValue: 0
};

const Card:React.FunctionComponent<{
  title: string,
  value: string | number,
  prefix?: string | ReactNode,
  postfix?: string,
  digits?: number,
  className?: string
}> = ({title, value, postfix = '', prefix, className, digits = 0}) => {
  myObject.totalValue = 0;
    return (
      <div className={`stat-card ${className || ""}`}>
        <div className="card-title paragraph paragraph-footnote">{(title)}</div>
        <div className="value-wrapper"><div className="value title title-medium">
          {((value) => {
            switch (typeof value) {
              case "number":
                return <>{prefix}{(value).toLocaleString('en', {minimumFractionDigits: digits, maximumFractionDigits: digits})}{postfix}</>

              case "string":
                return <>{prefix}{value + postfix}</>;

              default:
                return value;
            }
          })(value)}
        </div></div>
      </div>
    );
  };

export default Card;
