import React, { useState, useRef, useEffect } from "react";
import CalculatorField from './v-calculator-field';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import moment from "moment";
import { STAKING_RATE } from "../staking";

const startTime = moment().unix();

const Calculator:React.FunctionComponent<{}> = () => {

  const [value, setValue] = useState<number>(25000);
  const [timePassed, setTimePassed] = useState<number>(0);
  const [amount, setAmount] = useState(value);
  const slider = useRef(null);

  const calculateProfit = (amount: number, days: number): number => {
    const dailyProfit = amount*(STAKING_RATE/100)/365
    if (days) return dailyProfit + calculateProfit(amount+dailyProfit, days - 1);
    return 0;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed(moment().unix() - startTime);
    }, 2500);
    return () => {
      clearInterval(interval);
    }
  }, [])

  const dailyProfit = calculateProfit(amount, 1);

  return (
    <div className="calculator">
      <div className="data">
        <div className="flex-grow amount paragraph paragraph-footnote">
          Staking amount
          <div className="value title title-huge">
          {value.toLocaleString('us')}
          </div>
        </div>
        <div className="flex-grow interest paragraph paragraph-footnote">
          Interest
          <div className="value title title-huge">
            {STAKING_RATE.toLocaleString('en', {minimumFractionDigits: 1})}&#8201;%
          </div>
        </div>
      </div>
      <div className="slider">
        <Slider ref={slider} min={100} max={100000} value={value} onChange={(value) => setValue(value)} onAfterChange={() => setAmount(value)}/>
      </div>
      <div className="earnings">
        <CalculatorField
          value={(dailyProfit*timePassed/86400)}
          digits={9}
          title={"Earnings as you read this page"} />
        <CalculatorField
          value={dailyProfit}
          digits={2}
          title={"Daily"} />
        <CalculatorField
          value={calculateProfit(amount, 30)}
          digits={2}
          title={"Monthly"} />
        <CalculatorField
          value={calculateProfit(amount, 365)}
          digits={2}
          title={"Annual"} />
      </div>
      <a href="https://uri.ever.surf/stake"><button type="button" onClick={() => setValue(Math.floor(Math.random()*10000))}className="btn btn-primary btn-xl">Stake now</button></a>
    </div>
  );
}

export default Calculator;