export const WeightedAverage = (array: Array<number>) => {
  const values: Array<number> = [];
  const weights: {[key: string]: number} = {};
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (values.includes(element)) {
      weights[element.toString()]++;
    } else {
      values.push(element);
      weights[element.toString()] = 1;
    }
  }
  console.log(values);
  console.log(weights);
  return values.reduce((weighted, current) => weighted + current*weights[current.toString()], 0) / Object.values(weights).reduce((total, current) => total + current, 0);
  }

export default WeightedAverage;