import React, { FunctionComponent } from "react";
import { Button as BsButton, ButtonProps as BsButtonProps } from "react-bootstrap";
import styles from './Button.module.scss';

export interface ButtonProps extends Omit<BsButtonProps, 'variant'|'size'> {
  size?: BsButtonProps['size'] | "xl" | "sm",
  variant?: BsButtonProps['variant'] | "primary" | "bordered" | "transparent" | "semitransparent",
  disabled?: boolean,
  className?: string,
  children?: React.ReactNode,
  theme?: any,
  icon?: React.ReactNode | string,
}

export const Button: FunctionComponent<ButtonProps> = (props) => {
  const newProps = {...props, size: undefined} as BsButtonProps;
  if (props.size === "xl") {
    newProps.className = newProps.className ? newProps.className+' '+styles['btn-xl'] : styles['btn-xl'];
  } else if (props.size) {
    newProps.className = newProps.className ? newProps.className+' '+styles['btn-'+props.size] : styles['btn-'+props.size];
    newProps.size = props.size;
  }
  if (typeof props.icon === "string") {
    newProps.className = newProps.className ? `${newProps.className} ${styles['btn-icon']} ${styles['btn-icon-'+props.icon]}` : `${styles['btn-icon']} ${styles['btn-icon'+props.icon]}`;
  } else {
    newProps.children = <>{props.icon}{newProps.children}</>;
  }
  if (props.variant) {
    newProps.className = newProps.className ? `${newProps.className} ${styles['btn-'+props.variant]}` : styles['btn-'+props.variant];
  }
  return <BsButton {...newProps} className={`${styles.btn} ${newProps.className}`}>{typeof props.icon === "string" ? newProps.children : <>{props.icon}{newProps.children}</>}</BsButton>;
};

export default Button;