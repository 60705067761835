import { combineReducers } from "redux";

import {
  accountsReducer,
  transactionsReducer,
  balancesReducer,
  volumeReducer,
  layoutReducer,
  pulseReducer,
  depoolerReducer,
} from "./index";

export const rootReducer = combineReducers({
  accounts: accountsReducer,
  balances: balancesReducer,
  depooler: depoolerReducer,
  layout: layoutReducer,
  pulse: pulseReducer,
  transactions: transactionsReducer,
  volume: volumeReducer,
});
