import { FunctionComponent } from "react";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer: FunctionComponent<{}> = () => {
  return (
    <footer>
      <Container fluid>
        <Nav as="ul">
          <Nav.Item as="li">
            <Link to="/legal/surf-decentralization-policy" className="dod">Legal</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <a href="https://ever.surf/download/" target="_blank" className={"dod"}>Download</a>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/scard" className="dod">Security card</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/pulse" className="dod">Waves</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/beta" className="dod">Beta</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/donate" className="dod">Donate</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <a href="https://2022.ever.surf" target="_blank" className={"dod link link-outer"}>Annual report</a>
          </Nav.Item>
        </Nav>

        <div className="copyright">
          <a href="https://tnft.surf" target="_blank" rel="noopener noreferrer nofollow" className={"link link-outer"}>True NFT</a>
          <a href="https://everos.dev" target="_blank" rel="noopener noreferrer nofollow" className={"link link-outer"}>For Everscale Developers</a>
          <span>{(new Date()).getFullYear().toString()} &#169; Ever Surf</span>
        </div>
      </Container>
      <Container fluid className="footer-everscale paragraph paragraph-footnote">
        Ever Surf is a part of the <a href="https://everscale.network/ecosystem" target="_blank" rel="noopener noreferrer nofollow">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 884 201">
            <g>
              <path fill="#111111" d="M242.6,140.3V51h64.7v14.3h-49.4v23h39.8V102h-39.8v23.8h49.4v14.4H242.6L242.6,140.3z"/>
              <path fill="#111111" d="M331.9,140.3l-17.6-64.4H329l13.4,51.9h8.9l13.4-51.9h14.7l-17.5,64.4H331.9L331.9,140.3z"/>
              <path fill="#111111" d="M386.5,108c0-19.1,14.7-33.8,33.8-33.8c19.4,0,33.4,14.8,33.4,33.5v3.8h-52.9c1.3,10.1,9.6,17.9,19.5,17.9
                c13.4,0,17.9-9.6,17.9-9.6h14.7c0,0-6.4,22.1-32.5,22.1C401.2,142,386.5,127.2,386.5,108L386.5,108z M438.8,101.6
                c-1.3-7.9-8.3-14.9-18.5-14.9c-10.2,0-17.2,7-19,14.9H438.8z"/>
              <path fill="#111111" d="M466.3,140.3V75.9h14v9.8h0.4c0,0,4.3-10.8,18.1-10.8h7.7v13.8h-8.7c-10.5,0-17.5,7-17.5,17.2v34.4H466.3
                L466.3,140.3z"/>
              <path fill="#111111" d="M513,119.9h14.7c0,0,3.2,9.8,16.2,9.8c10,0,13.4-3.4,13.4-7.9c0-11.9-41.1-3.8-41.1-28.7
                c0-9.9,8.9-18.9,25.8-18.9c25.6,0,29.1,20.2,29.1,20.2h-14.4c0,0-2.5-8-14.7-8c-7.9,0-11.5,3.6-11.5,6.8
                c0,11.7,41.2,3.8,41.2,28.7c0,10.5-9.6,20.2-27.8,20.2C517.4,142,513,119.9,513,119.9z"/>
              <path fill="#111111" d="M580,108c0-19.1,14.7-33.8,33.4-33.8c26.8,0,31.9,24,31.9,24H631c0,0-3.8-11.5-17.6-11.5
                c-10.5,0-19.1,8.5-19.1,21.3c0,12.9,8.9,21.4,19.1,21.4c14,0,17.9-12.1,17.9-12.1h14.4c0,0-4.8,24.6-32.3,24.6
                C594.6,142,580,127.2,580,108L580,108z"/>
              <path fill="#111111" d="M655.6,122.1c0-11.1,9.1-20.4,25.8-20.4h20.2v-2.2c0-8-5.1-13.1-14-13.1c-12.1,0-15.1,9.3-15.1,9.3h-14.3
                c0,0,3.8-21.4,29.3-21.4c17.9,0,28.1,9.9,28.1,25.3v40.8h-14v-9.6h-0.4c0,0-6.3,11.2-22.6,11.2C663.9,142,655.6,133.5,655.6,122.1
                L655.6,122.1z M701.6,111.8h-19.4c-8,0-12.2,4.2-12.2,9.1c0,5,3.8,8.8,12.5,8.8C694.2,129.7,701.6,122.1,701.6,111.8L701.6,111.8z
                "/>
              <path fill="#111111" d="M730.9,140.3V51h14v76.8h14.7v12.5H730.9z"/>
              <path fill="#111111" d="M765.1,108c0-19.1,14.7-33.8,33.8-33.8c19.4,0,33.4,14.8,33.4,33.5v3.8h-52.9c1.3,10.1,9.6,17.9,19.5,17.9
                c13.4,0,17.9-9.6,17.9-9.6h14.7c0,0-6.4,22.1-32.5,22.1C779.8,142,765.1,127.2,765.1,108L765.1,108z M817.4,101.6
                c-1.3-7.9-8.3-14.9-18.5-14.9c-10.2,0-17.2,7-19,14.9H817.4z"/>
              <path fill="#6347F5" d="M102.6,51L52,101.6h89.3v89.3l50.6-50.6V51H102.6z"/>
            </g>
          </svg></a> core ecosystem
      </Container>
    </footer>
  );
};

export default Footer;
