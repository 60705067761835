import React, { ReactNode } from "react";
import Lottie, { Options } from 'react-lottie';

const Card:React.FunctionComponent<{
  title: string | ReactNode,
  description: ReactNode,
  className?: string,
  index?: number,
  Icon?: any
}> = ({title, description, index, className, Icon}) => {
  const defaultOptions:Options = {
    loop: true,
    autoplay: true, 
    animationData: Icon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  const resolution = ((index: number | undefined) => {
    switch (index) {
    case 1:
      return { h: 110, w: 110};
    case 3:
      return { h: 120, w: 120};
      break;
    case 4:
      return { h: 124, w: 222};
      break;
  
    default:
      return { h: 100, w: 100} 
      break;
  }})(index);
    return (
      <div className={`promo-card ${className}`}>
        {Icon && <Lottie options={defaultOptions}
                height={resolution.h}
                width={resolution.w}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}/>}
        {title       && <div className="title">{title}</div>}
        {description && <div className="description">{(description)}</div>}
      </div>
    );
  };

export default Card;
