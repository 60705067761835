import { ActionTypes } from "../../types/action-types";
import {
} from "../actions/constants";

interface InitialState {
  totalCount: number | undefined
}

const initialState: InitialState = {
  totalCount: undefined
};

export const volumeReducer = (state: InitialState = initialState, { type, payload }: ActionTypes) => {
  switch (type) {
    default:
      return state;
  }
};