import TONClient from "./ton-client";

import {
  FieldAggregation,
} from '@tonclient/core';

const surfHashes = ["207dc560c5956de1a2c1479356f8f3ee70a59767db2bf4788b1d61ad42cdad82"];

export const getAccountsCount = async () => {
  return await TONClient.aggregateItems({
    filter: {
      code_hash :{
        in: surfHashes
      }
    }, 
    collection: 'accounts' });
}
export const getTransactionsCount = async () => {
  return await TONClient.aggregateItems({
  filter: {
  }, 
  collection: 'transactions' });
}
export const getBalanceTotal = async () => {
  return await TONClient.aggregateAccounts({
    code_hash :{
      in: surfHashes
    }
  }, [{
    field: "balance",
    fn: "SUM"
  } as FieldAggregation]);
}
export const getVolumeTotal = async () => {
  return await TONClient.aggregateTransactions({
    code_hash :{
      in: surfHashes
    }
  }, [{
    field: "balance",
    fn: "SUM"
  } as FieldAggregation]);
}
export const getAccount = async (id: string) => {
  return await TONClient.queryAccounts(
    {
      id:{
        eq: id
      }
    }, 
    'id balance acc_type acc_type_name',
  );
}
export const getAccountFirstMessage = async (id: string) => {
  return await TONClient.queryMessages(
    {
      dst:{
        eq: id
      }
    }, 
    'created_at',
    [{
      path: "created_at",
      direction: "ASC"
    }],
    1
  );
}
export const getAccountTransactionsTotal = async (id: string) => {
  return await TONClient.aggregateTransactions(
    {
      account_addr:{
        eq: id
      }
    }
  );
}
export const getAccountVolumeTotal = async (id: string) => {
  return await TONClient.aggregateTransactions(
    {
      account_addr:{
        eq: id
      }
    }, [{
      field: "balance_delta",
      fn: "SUM"
    } as FieldAggregation]
  );
}
export const getAccountFeesTotal = async (id: string) => {
  return await TONClient.aggregateTransactions(
    {
      account_addr:{
        eq: id
      }
    }, [{
      field: "total_fees",
      fn: "SUM"
    } as FieldAggregation]
  );
}
export const getAccountBalanceGreaterThanCount = async (balance: string) => {
  return await TONClient.aggregateAccounts({
    code_hash :{
      in: surfHashes
    },
    balance: {
      lt: balance
    }
  });
}
export const getActiveAccountsCount = async (date: number) => {
  return await TONClient.aggregateAccounts(
    {
      code_hash :{
        in: surfHashes
      },
      last_paid: {
        gt: date
      }
    }, [{
      field: "",
      fn: "COUNT"
    } as FieldAggregation]
  );
}
