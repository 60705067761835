import { ActionTypes } from "../../types/action-types";
import {
} from "../actions/constants";

interface IInitialState {
  isDarkTheme: boolean;
}

const initialState: IInitialState = {
  isDarkTheme: window.matchMedia("(prefers-color-scheme: dark)").matches,
};

export const layoutReducer = (state: IInitialState = initialState, { type, payload }: ActionTypes) => {
  switch (type) {
    default:
      return state;
  }
};
