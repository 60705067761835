import { ActionTypes } from "../../types/action-types";
import {
} from "../actions/constants";

interface IInitialState {
  totalCount: number | undefined
}

const initialState: IInitialState = {
  totalCount: undefined
};

export const balancesReducer = (state: IInitialState = initialState, { type, payload }: ActionTypes) => {
  switch (type) {
    default:
      return state;
  }
};