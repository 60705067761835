import React, { useRef } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { ReactComponent as QRCodeDonate } from "../../assets/images/qr/donate.svg";

import { default as flowerAnimationData } from '../../assets/images/lottie/donate.json'
import MetaDecorator from "../utils/metaDecorator";

import Lottie, { Options } from 'react-lottie';

const Donate:React.FunctionComponent<{}> = () => {

  const whatIsStakingRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;


  const defaultOptions:Options = {
    loop: true,
    autoplay: true, 
    animationData: flowerAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
    <MetaDecorator
      title="Donate to Surf Dev Team"
      description="Make a non-trackable donation to Surf. Support privacy and decentralization. No personal data collected. Scan QR code to donate."
      keywords="everscale, decentralized blockchain, blockchain app, support developers, donation with smartphone, free dapp"
    />
    <Container fluid="xl" className="content-container">
      <Row>
        <Col>
          <div className="hero">
            <h1 className="title">Donate to Surf</h1>
            <div className="page-description paragraph paragraph-text">
              <p>The Surf Dev Team works to bring the blockchain technologies to people everyday life. We use all our knowledge and resources to make the world of free and open internet real.</p>
              <h3 className="subtitle title title-medium">Donation is a way you can join us in this venture</h3>
              <p>It’s not just about supporting the team and work they do. When you donate, you express that you share our values, our sense of freedom and partnership.</p>
            </div>
            <div className="buttons-container">
              <a href="https://uri.ever.surf/transfer/0:606545c3b681489f2c217782e2da2399b0aed8640ccbcf9884f75648304dbc77?amount=10000000000&text=donate"><button type="button" className="btn btn-primary btn-xl">Donate</button></a>
              <div className="qr-code"><QRCodeDonate/></div>
              <div className="promo promo-small">{"Make a donation in a flash —\nsimply scan a QR-code"}</div>
            </div>
            <div className="disclaimer paragraph paragraph-footnote">Your donates come directly to the Surf Dev Team. 
              It’s non-trackable and no personal data collected — that's what we stand by.
            </div>

            <div className="donate-hero-animation">
              <Lottie options={defaultOptions}
                height={150}
                width={260}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Donate;
