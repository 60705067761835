import React, { useContext } from "react";
import { Accordion, AccordionContext } from "react-bootstrap";

const AccordionPanel:React.FunctionComponent<{title: string, eventKey?: any, callback?: any}> = ({ title, children, eventKey = "0", callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Accordion.Item eventKey={eventKey} className={ isCurrentEventKey ? 'active' : '' }>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default AccordionPanel;