import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Redirect, useLocation } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import 'bootstrap/js/dist/collapse.js';
import MetaDecorator from "../utils/metaDecorator";


const Content: FunctionComponent<{title?: string, path?: string}> = ({title, path}) => {
  
  const { id } = useParams<{id: string}>();
  const location = useLocation();
  const ref = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>;
  const [content, setContent] = useState<any>(null);

  const pageTitle = (() => {
    if (path) 
      return path;
    return id;
  })();

  useEffect(() => {
    setContent('');
    async function getContent () {
      const file = await import(`../content/${pageTitle}.md`);
      const response = await fetch(file.default);
      const markdown = await response.text();
      await setContent(markdown);
    }
    getContent();
  }, [pageTitle]);

  useEffect(() => {
    if (location.hash && ref && ref.current && content) ref.current.querySelector(location.hash)!.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [content, location.hash]);
  
  console.log(useLocation());

  if ( pageTitle === '' || !pageTitle ) return (<Redirect
    to={{
      pathname: "/"
    }}
  />);

  if (content === null ) return (<></>);

  return (
    <>
      <MetaDecorator
        title={pageTitle.replace(/[-_]/g, " ").trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
        description=""
        keywords="everscale, decentralized blockchain, blockchain app, blockchain client, smart contracts, cryptocurrency wallet, multisig wallet, free dapp, free crypto wallet, mobile dapp, staking pools, buy evers, EVER tokens"
      />
      <Container fluid="lg">
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <h1>{title}</h1>
            <section className="content-wrapper" ref={ref}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Content;
