import gsap from "gsap";
import React, { useEffect, useState } from "react";

const myObject = {
  totalValue: 0
};

const CalculatorField:React.FunctionComponent<{value: number, title: string, digits: number}> = ({value, title, digits}) => {

  myObject.totalValue = 0;

  const multiplier:number = 10**digits;

  const [count, setCount] = useState(value*multiplier);

  useEffect(() => {
    gsap.to(myObject, {
      duration: 1,
      ease: "power4.out",
      totalValue: value*multiplier,
      roundProps: "totalValue",
      onUpdate: () => {
        setCount(myObject.totalValue);
      }
    });
  }, [value, multiplier]);

  return (
    <div className={`paragraph paragraph-label`}>
      {title}
      <div className="value title title-medium">{(count/multiplier).toLocaleString('en', {minimumFractionDigits: digits})}</div>
    </div>
  );
}

export default CalculatorField;