import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Button, Navbar, Nav, Container } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import { Scrollspy } from "../utils/Scrollspy";

import { STAKING_RATE } from './staking';
import MetaDecorator from "../utils/metaDecorator";

// const Header = React.forwardRef<HTMLDivElement, {
//   isActive?: boolean,
//   children?: React.ReactNode
// }>((props, ref) => {

const Header: FunctionComponent<{
  isActive?: boolean,
  children?: React.ReactNode
}> = () => {
  const [expanded, setExpanded] = useState(false);

  const getHeader = (location: string) => {
    switch (location) {
      case "beta":
        return "Beta Test";
      case "staking":
        return "Decentralized Staking";
      case "pulse":
        return "Blockchain Pulse";
      case "depooler":
        return "Everscale Staking";
      case "scard":
        return "Security Card";
      default:
        return "Everscale Browser";
    }
  }
  
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
  ];

  useEffect(() => {
    document.addEventListener('click', () => setExpanded(false));
    return () => {
      document.removeEventListener('click', () => setExpanded(false));
    }
  }, [])

  const header = getHeader(useLocation().pathname.split('/').filter(Boolean)[0]);
  const location = useLocation().pathname.split('/').filter(Boolean)[0];
console.log(location);
  return (
    <>
    <MetaDecorator
      title="Beta Testing in Blockchain"
      description="Become a Surf Beta Tester. Join testing without signing up or application submitting. Scan QR code to get started and blend into our testers community."
      keywords="open beta testing, beta testing apps, beta testing crypto, beta testing release, join the beta testflight"
    />
    <Scrollspy sectionRefs={sectionRefs}>
      {({ elementsScrolled }) => (<>
        <header id="header" ref={sectionRefs[0]} className={`${expanded ? "active" : ""} ${cn({"fixed": elementsScrolled[0]})}`}>
          <Navbar expand="lg" expanded={expanded} fixed={"top"} className={cn({"fixed-shadow": elementsScrolled[0]})}>
                <Navbar.Brand>
                  {location
                    ? <Link to="/" onClick={() => setExpanded(false)} className="logo"><Logo/><span className="d-none d-lg-inline-block">Ever Surf · {header}</span><span className="d-none d-sm-inline-block d-lg-none">Ever Surf</span></Link>
                    : <span className="logo"><Logo/><span className="d-none d-lg-inline-block">Ever Surf · {header}</span><span className="d-none d-sm-inline-block d-lg-none">Ever Surf</span></span>
                  }
                </Navbar.Brand>

                <a href="https://ever.surf/download/" onClick={() => setExpanded(false)} className="d-md-inline-block d-lg-none"><Button className="btn-icon btn-icon-arrow-right">Get it now</Button></a>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={(e) => {e.stopPropagation();setExpanded(expanded ? false : true)}} />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <NavLink className="nav-link" to="/staking" onClick={() => setExpanded(false)}>Savings<sup>+&thinsp;{STAKING_RATE.toLocaleString('en', {minimumFractionDigits: 1})}%</sup></NavLink>
                    {/* <NavLink className="nav-link" to="/pulse" onClick={() => setExpanded(false)}>Waves</NavLink> */}
                    <NavLink className="nav-link" to="/depooler" onClick={() => setExpanded(false)}>Depooler</NavLink>
                    {/* <NavDropdown title="English" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">English</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Русский</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Espanol</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.4">German</NavDropdown.Item>
                    </NavDropdown> */}
                    <Nav.Link active={false} href="https://ever.surf/token/" target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Token</Nav.Link>
                    <Nav.Link active={false} href="https://help.ever.surf/" target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Help</Nav.Link>
                    <a href="https://ever.surf/download/" rel="noopener noreferrer nofollow" target="_blank" onClick={() => setExpanded(false)} className="d-none d-lg-inline-flex"><Button className="btn-icon btn-icon-arrow-right">Get it now</Button></a>
                  </Nav>
                </Navbar.Collapse>
          </Navbar>
        </header>
      </>)}
    </Scrollspy>
    </>
  );
};

export default Header;
