import React, { ReactNode } from "react";
import Lottie, { Options } from 'react-lottie';

const Card:React.FunctionComponent<{
  description: ReactNode,
  title: string,
  className?: string,
  Icon: any
}> = ({description, title, className, Icon}) => {
  const defaultOptions:Options = {
    loop: true,
    autoplay: true, 
    animationData: Icon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
    return (
      <div className={`promo-card ${className}`}>
        <Lottie options={defaultOptions}
                height={100}
                width={100}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}/>
        {title && <div className="title title-small">{(title)}</div>}
        {description && <div className="description paragraph paragraph-footnote">{(description)}</div>}
      </div>
    );
  };

export default Card;
